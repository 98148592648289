import React, { FC } from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import { MegaMenu } from 'components/padi-ui/header/types';
import MobileMenuNavButton from '../atoms/menu-nav-button';

export interface Props {
  hideHeaderMenu: () => void;
  megaMenuNavigator?: MegaMenu[]; //This is a navigator where you push a megaMenu to show it's contents
  insertMegaMenu: (megaMenu: MegaMenu) => void;
  megaMenus: MegaMenu[];
}

const MobileMenu: FC<Props> = ({ megaMenuNavigator, insertMegaMenu, hideHeaderMenu, megaMenus }) => {
  return (
    <div className="ml-24 mr-22">
      {/* Inside */}
      {megaMenuNavigator && megaMenuNavigator?.length > 0 && (
        <div className="pt-24">
          {megaMenuNavigator.map((megaMenu, index) => (
            <div key={`megaMenu-${index}`} className="pb-36" onClick={hideHeaderMenu}>
              {megaMenu?.columns &&
                megaMenu?.columns.map((column, index) => (
                  <div key={`column-${index}`}>
                    {column?.menus &&
                      column?.menus?.map((menu, index) => (
                        <div key={`menu-${index}-${menu?.title}`} className="pb-20">
                          <div className="font-bold">
                            {menu?.titleUrl ? <Link link={menu?.titleUrl}>{menu?.title}</Link> : menu?.title}
                          </div>
                          {menu?.links &&
                            menu?.links.map((link, index) => (
                              <div key={`link-${index}-${link?.label}`} className="py-4">
                                <Link
                                  link={link?.url}
                                  className={link?.style == 'blue' ? 'font-bold text-padi-blue' : 'text-black'}
                                >
                                  {link?.label}
                                </Link>
                              </div>
                            ))}
                        </div>
                      ))}
                  </div>
                ))}
            </div>
          ))}
        </div>
      )}
      {/* Outside */}
      {megaMenuNavigator && megaMenuNavigator.length <= 0 && (
        <div>
          {megaMenus.map((megaMenu, index) => (
            <div key={`megaMenu-${index}`} className="group h-full">
              <MobileMenuNavButton
                key={megaMenu?.label}
                megaMenuNavigator={megaMenuNavigator}
                megaMenu={megaMenu}
                onClick={() => insertMegaMenu(megaMenu)}
                hideHeaderMenu={hideHeaderMenu}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
