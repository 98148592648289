import FeatureSectionDynamicList from 'components/padi-ui/feature-section/dynamic-list';

export interface PadiProductFeatureSectionDynamicListProps {
  product: Record<string, any>;
  titleHtag?: string;
  attributeSection: string;
  bgColor: string;
}
const PadiProductFeatureSectionDynamicList: React.FC<PadiProductFeatureSectionDynamicListProps> = ({
  product,
  titleHtag,
  attributeSection,
  bgColor,
}) => {
  try {
    const productContent = JSON.parse(product?.variants?.[0].attributes[attributeSection]);
    const title = productContent?.title ?? '';
    const features = productContent?.features ?? [];

    return <FeatureSectionDynamicList title={title} titleHtag={titleHtag} bgColor={bgColor} features={features} />;
  } catch (e) {
    return <></>;
  }
};

export default PadiProductFeatureSectionDynamicList;
