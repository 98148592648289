import { useState } from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import Image from 'frontastic/lib/image';
import PromotionalTileCTAButton from './promotional-tile-cta-button';
import { PromotionalTileProps, PromotionalTileStyles } from './types';

function tileStyleProp(style: PromotionalTileStyles) {
  switch (style) {
    case PromotionalTileStyles.blueLight:
      return {
        background: 'bg-padi-blue-light',
        textColor: 'text-gray-darkest',
        ctaBorderColor: 'border-padi-gray-darkest',
        ctaTextColor: 'text-padi-gray-darkest',
      };
    case PromotionalTileStyles.grayLight:
      return {
        background: 'bg-padi-gray-light',
        textColor: 'text-gray-darkest',
        ctaBorderColor: 'border-padi-gray-darkest',
        ctaTextColor: 'text-padi-gray-darkest',
      };
    case PromotionalTileStyles.grayLighter:
      return {
        background: 'bg-padi-gray-lighter',
        textColor: 'text-gray-darkest',
        ctaBorderColor: 'border-padi-gray-darkest',
        ctaTextColor: 'text-padi-gray-darkest',
      };
    case PromotionalTileStyles.grayDarkest:
      return {
        background: 'bg-padi-gray-darkest',
        textColor: 'text-white',
        ctaBorderColor: 'border-white',
        ctaTextColor: 'text-white',
      };
    case PromotionalTileStyles.black:
      return {
        background: 'bg-black',
        textColor: 'text-white',
        ctaBorderColor: 'border-white',
        ctaTextColor: 'text-white',
      };
    case PromotionalTileStyles.imageBackgroundLight:
      return {
        background: '',
        textColor: 'text-gray-darkest',
        ctaBorderColor: 'border-padi-gray-darkest',
        ctaTextColor: 'text-padi-gray-darkest',
      };
    case PromotionalTileStyles.imageBackgroundDark:
      return {
        background: '',
        textColor: 'text-white',
        ctaBorderColor: 'border-white',
        ctaTextColor: 'text-white',
      };
    default:
      return {
        background: 'bg-padi-blue-light',
        textColor: 'text-gray-darkest',
        ctaBorderColor: 'border-padi-gray-darkest',
        ctaTextColor: 'text-padi-gray-darkest',
      };
  }
}

const PromotionalTile: React.FC<PromotionalTileProps> = ({ title, subTitle, style, url, image, ctaStyle }) => {
  const styleProp = tileStyleProp(style);
  const isShowTextLayer = title || subTitle || ctaStyle;
  const defaultBrightness = isShowTextLayer ? 'brightness-75' : 'brightness-100';
  const [imageHovered, setImageHovered] = useState(false);

  return (
    <Link link={url}>
      <div
        className={`relative flex size-full flex-col-reverse rounded-lg ${styleProp.background}`}
        onMouseEnter={() => setImageHovered(true)}
        onMouseLeave={() => setImageHovered(false)}
      >
        {image?.media?.file && (
          <>
            <Image {...image} className="absolute size-full rounded-lg object-cover" />
            {isShowTextLayer && (
              <div className="absolute inset-0 rounded-lg">
                <div
                  className={`size-full rounded-lg bg-padi-gray-darkest opacity-35 ${
                    imageHovered ? 'brightness-[1.2]' : defaultBrightness
                  }`}
                />
              </div>
            )}
          </>
        )}
        {isShowTextLayer && (
          <div className={`absolute inset-x-30 bottom-30 ${styleProp.textColor}`}>
            {title && <div className="text-lg font-semibold">{title}</div>}
            {subTitle && <div className="text-base font-regular">{subTitle}</div>}
            {ctaStyle && (
              <div className="pt-20">
                <PromotionalTileCTAButton
                  title={ctaStyle?.title}
                  style={ctaStyle?.style}
                  url={url}
                  borderColor={styleProp.ctaBorderColor}
                  textColor={styleProp.textColor}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Link>
  );
};

export default PromotionalTile;
