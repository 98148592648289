import { useEffect, useMemo, useState } from 'react';
import { sdk } from 'sdk';
import { Product } from 'shared/types/product/Product';
import Tabs from './tabs';
import CourseTemplate from './child-templates/course';

export interface BundleTabsProps {
  productIds: string[];
}

const BundleTabs: React.FC<BundleTabsProps> = ({ productIds }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product>();

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await Promise.allSettled(
        productIds.map((id) => {
          return sdk.callAction({
            actionName: '/product/getProduct',
            query: {
              id,
            },
          });
        }),
      );
      const resProducts: Product[] = [];
      response.forEach((result) => {
        if (result.status === 'fulfilled' && (result.value as any)?.data) {
          resProducts.push((result.value as any).data);
        }
      });
      if (resProducts.length > 0) {
        setProducts(resProducts);
        setSelectedProduct(resProducts[0]);
      }
    };

    fetchProducts();
  }, [productIds]);

  const childContent = useMemo(() => {
    if (!selectedProduct) {
      return null;
    }
    switch (selectedProduct.productType) {
      case 'Course':
        return <CourseTemplate product={selectedProduct} />;
      default:
        return null;
    }
  }, [selectedProduct]);

  if (!products[0]) {
    return null;
  }

  return (
    <div>
      <div className="mx-auto max-w-7xl px-24 pb-12 pt-24 md:px-24 md:py-12 lg:rounded-md lg:p-36 lg:px-32">
        <div className="m-auto flex-1 bg-blue-950 p-24">
          <div className="m-auto mb-24 text-center text-3xl font-medium text-white">Included</div>
          <Tabs
            products={products}
            setSelectedProduct={setSelectedProduct}
            selectedProductId={selectedProduct?.productId}
          />
        </div>
      </div>
      {childContent}
    </div>
  );
};

export default BundleTabs;
