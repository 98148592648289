'use client';

import React from 'react';
import SSOCallback from 'components/padi-ui/sso-callback';

const SSOCallbackTastic = () => {
  return <SSOCallback />;
};

export default SSOCallbackTastic;
