import { buildi18nLocales } from 'project.config';

const locales = buildi18nLocales();

const i18nConfig = {
  defaultLocale: 'en-us',
  // Keep in sync with project.config.ts
  locales: locales,
  rtlLngs: [
    'ar',
    'ar-sa',
    'shu',
    'sqr',
    'ssh',
    'xaa',
    'yhd',
    'yud',
    'aao',
    'abh',
    'abv',
    'acm',
    'acq',
    'acw',
    'acx',
    'acy',
    'adf',
    'ads',
    'aeb',
    'aec',
    'afb',
    'ajp',
    'apc',
    'apd',
    'arb',
    'arq',
    'ars',
    'ary',
    'arz',
    'auz',
    'avl',
    'ayh',
    'ayl',
    'ayn',
    'ayp',
    'bbz',
    'pga',
    'he',
    'iw',
    'ps',
    'pbt',
    'pbu',
    'pst',
    'prp',
    'prd',
    'ug',
    'ur',
    'ydd',
    'yds',
    'yih',
    'ji',
    'yi',
    'hbo',
    'men',
    'xmn',
    'fa',
    'jpr',
    'peo',
    'pes',
    'prs',
    'dv',
    'sam',
    'ckb',
  ],
};

export default i18nConfig;
