'use client';

import BentoBox, { BentoBoxProps } from 'components/padi-ui/bento-box';
import { TasticProps } from 'frontastic/tastics/types';

export type Bento33_33_33TasticProps = Pick<BentoBoxProps, 'promotionalTiles'>;

const Bento33_33_33Tastic = ({ data }: TasticProps<Bento33_33_33TasticProps>) => {
  return <BentoBox promotionalTiles={data.promotionalTiles} gridType="33-33-33" />;
};

export default Bento33_33_33Tastic;
