'use client';

import React from 'react';
import PadiSimplifiedHeader from 'components/padi-ui/simplified-header';
import { PadiHeaderProps } from 'components/padi-ui/header/types';
import { TasticProps } from '../../types';

const PadiSimplifiedHeaderTastic = ({
  data,
}: TasticProps<PadiHeaderProps>) => {
  return (
    <div id="padi-header-container" className="z-9 w-full" >
      <PadiSimplifiedHeader
        logo={data.logo}
        logoLink={data.logoLink}
      />
    </div>
  );
};
export default PadiSimplifiedHeaderTastic;
