import { SDKResponse } from '@commercetools/frontend-sdk';
import useSWR from 'swr';
import { sdk } from 'sdk';
import { ProjectCurrencies, ProjectSettings } from './types';

const useProjectSettings = () => {
  const response = useSWR<SDKResponse<any>>('action/project/getProjectSettings', () =>
    sdk.callAction({ actionName: 'project/getProjectSettings' }),
  );

  const data = response.data?.isError ? ({} as ProjectSettings) : (response.data?.data as ProjectSettings);

  return { ...response, data };
};

export const useProjectCurrencies = () => {
  // const projectCurrencies
  const response = useSWR<SDKResponse<any>>('action/padi-ct-project/currencies', () =>
    sdk.callAction({ actionName: 'padi-ct-project/currencies' }),
  );

  const data = response.data?.isError
    ? ({ currencies: [] } as ProjectCurrencies)
    : (response.data?.data as ProjectCurrencies);

  return { ...response, data };
};

export const getZoneKeyByCountryCode = async (countryCode: string) => {
  const res = await sdk.callAction({
    actionName: 'padi-ct-project/getZoneByCountryCode',
    payload: {
      countryCode,
    },
  });

  return (res.isError ? {} : res.data) as any;
};

export default useProjectSettings;
