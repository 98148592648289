import { FC, useEffect } from 'react';
import { useParams } from 'next/navigation';
import { XMarkIcon as CloseIcon } from '@heroicons/react/24/solid';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
//import useScrollBlock from 'helpers/hooks/useScrollBlock';
import { desktop } from 'helpers/utils/screensizes';
import i18nConfig from 'i18n.config';

export interface Props extends ReactModalProps {
  closeButton?: boolean;
}

const Modal: FC<Props> = ({ children, style, preventScroll, closeButton, className = '', ...props }) => {
  const [isDesktopSize] = useMediaQuery(desktop);

  //const { blockScroll } = useScrollBlock();
  const { locale } = useParams();
  const langCode = locale.split('-');
  const direction = i18nConfig.rtlLngs.indexOf(langCode[0]) > -1 ? 'rtl' : 'ltr';

  const modalStyle: ReactModalProps['style'] = {
    overlay: {
      zIndex: 51,
      backgroundColor: 'rgba(127 ,127, 127, .3)',
      ...style?.overlay,
    },
    content: {
      top: '50%',
      left: direction == 'rtl' ? 'auto' : '50%',
      right: direction == 'rtl' ? '50%' : 'auto',
      bottom: 'auto',
      transform: direction == 'rtl' ? 'translate(50%, -50%)' : 'translate(-50%, -50%)',
      maxWidth: isDesktopSize ? 800 : '100%',
      position: 'relative',
      padding: 0,
      ...style?.content,
    },
  };

  useEffect(() => {
    if (preventScroll) document.body.style.overflow = props.isOpen ? 'hidden' : 'auto';
  }, [props.isOpen, preventScroll]);
  /*
  useEffect(() => {
    blockScroll(props.isOpen);
  }, [props.isOpen, blockScroll]);*/

  return (
    <ReactModal
      {...props}
      ariaHideApp={false}
      style={modalStyle}
      preventScroll={preventScroll}
      className={`${className} relative rounded-lg`}
    >
      {closeButton && (
        <CloseIcon
          className="absolute right-20 top-20 size-24 cursor-pointer text-secondary-black"
          onClick={props.onRequestClose}
        />
      )}
      {children}
    </ReactModal>
  );
};

export default Modal;
