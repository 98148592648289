import { useState, useEffect } from 'react';
import HeaderSection from 'components/padi-ui/header-section';
import { useFormat } from 'helpers/hooks/useFormat';
import { getLocalStorage } from 'helpers/utils/localStorage';
import { SearchParams } from 'types/next';
import { ProjectConfig } from 'types/project-config';

export interface PadiDiveShopHeaderProps {
  bgColor: string;
  textAlign: 'left' | 'right' | 'center';
  searchParams?: SearchParams;
  projectConfig?: ProjectConfig;
}

const PadiDiveShopHeader: React.FC<PadiDiveShopHeaderProps> = ({ bgColor, textAlign, searchParams, projectConfig }) => {
  const [data, setData] = useState(null);
  const [body, setBody] = useState(``);
  const [isLoading, setLoading] = useState(true);

  const irra = searchParams?.irra;
  const domains = projectConfig?.padiDomains;

  useEffect(() => {
    const diveShopLocalStorage = getLocalStorage('affiliateDiveShop');
    const localStorageExist = !!diveShopLocalStorage;
    let diveShopId = '';

    if (irra || localStorageExist) {
      diveShopId = !irra && localStorageExist ? diveShopLocalStorage : irra;
    }

    if (diveShopId) {
      const travelDomain = domains && 'travel' in domains ? domains?.travel : null;
      if (travelDomain) {
        const diveShopEndpoint = `${travelDomain}/api/v2/dsl/dive-shops`;

        fetch(`${diveShopEndpoint}/${diveShopId}/`)
          .then((res) => res.json())
          .then((data) => {
            const { results } = data;
            const { address, diveShopName, phone } = results[0];
            const { addressLine_1, addressLine_2, city, state, zipCode, country } = address;
            const { code: countryCode } = country;
            const addressString = `<span class="text-gray-500">${addressLine_1}, ${addressLine_2}<br>${city}, ${state} ${zipCode} ${countryCode}</span>`;
            setBody(
              `<div class="font-bold">${diveShopName}</div>${addressString}<br><span class="text-gray-500">${phone}</span>`,
            );
            setData(data);
            setLoading(false);
          });
      }
    }
  }, []);

  const { formatMessage } = useFormat({ name: 'padi' });
  const title = formatMessage({ id: 'yourDiveShop', defaultMessage: 'Your Dive Shop' });

  return body ? (
    <HeaderSection eyebrow="" title={title} titleSize="text-3xl" body={body} bgColor={bgColor} textAlign={textAlign} />
  ) : (
    <></>
  );
};

export default PadiDiveShopHeader;
