'use client';

import AnnouncementBanner, { AnnouncementBannerProps } from 'components/padi-ui/announcement-banner';
import { TasticProps } from '../../types';

const AnnouncementBannerTastic = ({ data }: TasticProps<AnnouncementBannerProps>) => {
  return <AnnouncementBanner {...data} />;
};

export default AnnouncementBannerTastic;
