import React from 'react';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import { isStringHtml } from 'helpers/utils/isStringHtml';

export interface Stat {
  stat: string;
  description: string;
}

export interface StatsProps {
  stats: Stat[];
  bgColor: string;
}

const Stats: React.FC<StatsProps> = ({ stats, bgColor }) => {
  const columnClass = `grid-cols-${Math.min(4, stats.length)}`;
  const classNameMain = `bg-${bgColor} pb-32 pt-32 lg:pt-64 lg:pb-64`;

  return (
    <div className={classNameMain}>
      <div className="mx-auto max-w-7xl px-24 lg:px-32">
        <dl className={`grid-cols-1 text-center lg:grid lg:gap-x-34 ${columnClass}`}>
          {stats.map((stat) => (
            <div key={stat.stat} className="mb-40 flex flex-col last:mb-0 lg:mb-0">
              <dt className="text-3xl font-semibold">{stat.stat}</dt>
              <dd className="mt-4 flex flex-auto flex-col text-base leading-6">
                <div className="prose max-w-none text-padi-gray-darker ">
                  {isStringHtml(stat.description) ? (
                    <div className="first:mt-0" dangerouslySetInnerHTML={{ __html: stat.description }} />
                  ) : (
                    <Markdown className="first:mt-0" markdown={stat.description} />
                  )}
                </div>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default Stats;
