import CtaSection from 'components/padi-ui/cta-section';

export interface ProductCtaActionProps {
  product: Record<string, any>;
  titleHtag: string;
  attributeSection: string;
  bgColor: string;
  image: {
    media: {
      alt: string;
      file: string;
    };
    title: string;
  };
  imagePosition?: 'left' | 'right';
}

const ProductCtaAction: React.FC<ProductCtaActionProps> = ({
  product,
  titleHtag,
  attributeSection,
  bgColor,
  image,
  imagePosition = 'left',
}) => {
  try {
    const productContent = JSON.parse(product?.variants?.[0].attributes[attributeSection]);
    const eyebrow = productContent?.eyebrow ?? '';
    const title = productContent?.title ?? '';
    const subtitle = productContent?.subtitle ?? '';
    const body = productContent?.body ?? '';
    const list = productContent?.list ?? {};
    const ctaLink = {
      link: '',
      openInNewWindow: false,
      type: '',
    };

    return (
      <CtaSection
        image={image}
        eyebrow={eyebrow}
        title={title}
        titleHtag={titleHtag}
        subtitle={subtitle}
        body={body}
        list={list}
        ctaLabel=""
        ctaLink={ctaLink}
        bgColor={bgColor}
        imagePosition={imagePosition}
      />
    );
  } catch (e) {
    return <></>;
  }
};

export default ProductCtaAction;
