'use client';

import React from 'react';
import ListDescription, { ListDescriptionProps } from 'components/padi-ui/list/description';
import { TasticProps } from 'frontastic/tastics/types';

const ListDescriptionTastic = ({ data }: TasticProps<ListDescriptionProps>) => {
  return <ListDescription title={data.title} description={data.description} items={data.items} />;
};

export default ListDescriptionTastic;
