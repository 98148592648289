import React, { useEffect, useState } from 'react';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import { isStringHtml } from 'helpers/utils/isStringHtml';
import { ExclamationTriangleIcon, CheckCircleIcon, XMarkIcon, InformationCircleIcon } from '@heroicons/react/20/solid';

export interface AlertProps {
  body?: string;
  alertType: 'warning' | 'error' | 'success' | 'information';
  label?: string;
  links?: Array<{
    ctaLabel: string;
    ctaLink: {
      link: string;
      openInNewWindow: boolean;
      type: string;
    };
  }>;
  collapsible?: boolean;
  accent?: boolean;
}

const alertIcons = {
  warning: ExclamationTriangleIcon,
  error: ExclamationTriangleIcon,
  success: CheckCircleIcon,
  information: InformationCircleIcon,
};

const Alert: React.FC<AlertProps> = ({ body, alertType, label, links, collapsible = false, accent = false }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const alertClasses = {
    warning: 'text-yellow-700 border-yellow-700',
    error: 'text-red-600 border-red-600',
    success: 'text-green-700 border-green-700',
    information: 'text-cyan-700 border-cyan-700',
  };

  const IconComponent = alertIcons[alertType];
  const baseClass = `p-16 bg-white ${alertClasses[alertType]}`;
  const accentClass = accent ? `border-l-4` : `rounded-md`;

  const handleClose = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (!label && !body) {
      setIsVisible(false);
    }
    if (label || body) {
      setIsVisible(true);
      setIsLoading(false);
    }
  }, [label, body, isLoading]);

  return (
    <>
      {!isLoading && isVisible && (
        <div className={`${baseClass} ${accentClass}`} role="alert">
          <div className="flex">
            <div className="mr-12 h-20 w-20 flex-shrink-0">
              <IconComponent aria-hidden="true" />
            </div>
            <div>
              {label && <p className="mb-4 text-sm font-semibold">{label}</p>}
              <div className="prose">
                {body &&
                  (isStringHtml(body) ? (
                    <div className="mb-4 text-sm" dangerouslySetInnerHTML={{ __html: body }} />
                  ) : (
                    <Markdown className="mb-4 text-sm prose-p:m-0" markdown={body} />
                  ))}
              </div>
              {links && links.length > 0 && (
                <div className="mt-16 flex flex-wrap items-center space-x-12 text-xs font-semibold">
                  {links.map((link, index) => (
                    <a
                      key={index}
                      href={link.ctaLink.link}
                      target={link.ctaLink.openInNewWindow ? '_blank' : '_self'}
                      rel={link.ctaLink.openInNewWindow ? 'noopener noreferrer' : undefined}
                      className={`${index === 0 ? 'ml-0' : 'ml-12'}`}
                    >
                      {link.ctaLabel}
                    </a>
                  ))}
                </div>
              )}
            </div>

            {collapsible && (
              <div className="ml-auto pl-3">
                <div className="">
                  <button
                    type="button"
                    onClick={handleClose}
                    className="inline-flex rounded-md hover:bg-opacity-25 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    <span className="sr-only">Dismiss</span>
                    <XMarkIcon aria-hidden="true" className="h-20 w-20 " />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Alert;
