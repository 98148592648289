import React, { useEffect, useState } from 'react';
import { useRouter, useSearchParams, useParams } from 'next/navigation';
import { Popover } from '@headlessui/react';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { ReferenceFieldLink } from 'components/padi-ui/header/types';
import { useFormat } from 'helpers/hooks/useFormat';
import usePath from 'helpers/hooks/usePath';
import { ProjectConfig } from 'types/project-config';

type Props = {
  projectConfig: ProjectConfig;
  userMenu?: ReferenceFieldLink[];
};

const LoggedOut = (props: Props) => {
  const projectConfig = props.projectConfig;
  const userMenu = props.userMenu;
  const router = useRouter();
  const { path } = usePath();
  const searchParams = useSearchParams();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const goToLoginPage = () => router.push(`/login?lvp=${getDeeplink()}`);
  const goToRegisterPage = () => router.push(`/login?loginPath=sign-up&lvp=${getDeeplink()}`);
  const getDeeplink = () => {
    let lvp = searchParams.get('lvp')?.trim() || '';
    if (!lvp) {
      const query = searchParams.toString() ? `?${searchParams.toString()}` : '';
      if (query || path !== '/') {
        lvp = `${path}${query}`;
      }
      if (lvp && path && path.startsWith('/')) {
        lvp = lvp.substring(1);
      }
    }
    return lvp;
  };

  const padiDomains = projectConfig?.padiDomains;
  const [proSiteUrl, setProSiteUrl] = useState<string>('');
  useEffect(() => {
    setProSiteUrl(`${padiDomains?.pro}/me/dashboard` || '');
  }, [proSiteUrl]);

  return (
    <div className="w-[235px] p-14">
      <Popover.Button as="div" className="w-full">
        <Button variant="primary" className="w-full py-12 text-16 leading-[16px]" onClick={goToLoginPage}>
          {formatAccountMessage({ id: 'sign.in', defaultMessage: 'Sign in' })}
        </Button>
      </Popover.Button>
      <Popover.Button as="div" className="mb-20 mt-10 w-full">
        <Button variant="secondary" className="w-full py-12 text-16 leading-[16px]" onClick={goToRegisterPage}>
          {formatAccountMessage({ id: 'create.account', defaultMessage: 'Create Account' })}
        </Button>
      </Popover.Button>
      {userMenu &&
        userMenu.length &&
        userMenu?.map((link, index) => (
          <div key={`link-${index}-${link?.label}`}>
            <Link link={link?.url} className="mt-10 block w-fit">
              <Typography className="text-primary-black hover:underline">{link?.label}</Typography>
            </Link>
          </div>
        ))}
    </div>
  );
};

export default LoggedOut;
