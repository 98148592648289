import React, { useMemo } from 'react';

interface BadgeProps {
  body: string;
  color: 'red' | 'blue' | 'white';
}

const Badge: React.FC<BadgeProps> = ({ body, color }) => {
  const classNames = useMemo(() => {
    const colorMapping = {
      red: 'bg-padi-red-darker text-white',
      blue: 'bg-padi-blue-darker text-white',
      white: 'bg-white text-gray-darkest',
    };
    return `rounded-md px-8 py-4 font-medium text-12 truncate ${colorMapping[color]}`;
  }, [color]);

  return <div className={classNames}>{body}</div>;
};

export default Badge;
