import { Reference } from 'types/reference';
import { ImageProps } from 'frontastic/lib/image';

export enum PromotionalTileStyles {
  blueLight = 'blueLight',
  grayLight = 'grayLight',
  grayLighter = 'grayLighter',
  grayDarkest = 'grayDarkest',
  black = 'black',
  imageBackgroundLight = 'imageBackgroundLight',
  imageBackgroundDark = 'imageBackgroundDark',
}

export interface PromotionalTileCTAProps {
  title: string;
  style: 'secondary' | 'textlink';
  url?: Reference;
  borderColor: string;
  textColor: string;
}

export interface PromotionalTileProps {
  title?: string;
  subTitle?: string;
  ctaStyle?: Pick<PromotionalTileCTAProps, 'title' | 'style'>;
  image?: ImageProps;
  style: PromotionalTileStyles;
  url?: Reference;
}
