import { useState, useEffect, useCallback } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import { isStringHtml } from 'helpers/utils/isStringHtml';
import Button from 'components/commercetools-ui/atoms/button';
import useTrack from '../hooks/useTrack';
import { getUserData, getUserIdToken } from 'helpers/padi/cognito';
import { ProjectConfig } from 'types/project-config';
import usePath from 'helpers/hooks/usePath';
import { useAccount } from 'frontastic';
import FeedbackIconLayer from 'components/commercetools-ui/atoms/button/feedbackIconLayer';
import { useFormat } from 'helpers/hooks/useFormat';

export interface PadiRedeemReferralContentProps {
  body?: string;
  ctaLabel?: string;
  projectConfig?: ProjectConfig;
}

const PadiRedeemReferralContent: React.FC<PadiRedeemReferralContentProps> = ({ body, ctaLabel, projectConfig }) => {
  const searchParams = useSearchParams();
  const { path } = usePath();
  const router = useRouter();
  const { getProjectConfig, refreshSession } = useAccount();

  const { trackRedeemReferralLink } = useTrack();
  const [userData, setUserData] = useState<object>();
  const [userSub, setUserSub] = useState<string>();
  const [idToken, setIdToken] = useState<string>();
  const [referralId, setReferralId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  const { formatMessage: formatMessageAccount } = useFormat({ name: 'account' });

  const sendRefereePayload = async (idToken: string, referralId: string, userSub: string) => {
    const { setting: globalEndpoint } = await getProjectConfig('EXTENSION_PADI_APIS_GLOBAL_API_DOMAIN');
    const response = await fetch(`${globalEndpoint}/r/referral/api/redeem`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      method: 'POST',
      body: JSON.stringify({
        referrerSub: referralId,
        refereeSub: userSub,
      }),
    });
    if (response.ok) {
      return;
    }
  };

  const goToLoginPage = (lvp: string) => router.push(`/login/?lvp=${lvp}`);

  const handleButtonClick = async () => {
    setIsButtonLoading(true);
    // Authenticated.
    if (referralId && userSub && idToken) {
      handleRedemption(referralId, userSub, idToken);
    } else {
      const returnPath = path.startsWith('/') ? path.substring(1) : path;
      goToLoginPage(`${returnPath}?referralId=${referralId}`);
    }
  };

  const handleRedemption = useCallback(
    async (referralId: string, userSub: string, idToken: string) => {
      trackRedeemReferralLink(referralId, userSub);
      await sendRefereePayload(idToken, referralId, userSub);
      await refreshSession();
      router.push(`/courses/open-water-diver/p/60462-1B2C/`);
    },
    [referralId, userSub, idToken],
  );

  useEffect(() => {
    const getIdToken = getUserIdToken();
    const referralId = searchParams.get('referralId');
    const userData = getUserData();
    if (getIdToken) setIdToken(getIdToken);
    if (referralId) setReferralId(referralId);
    if (userData) {
      refreshSession();
      setUserData(userData);
      setUserSub(userData?.sub);
    }
    setIsLoading(false);
  }, []);

  return (
    <>
      {!isLoading ? (
        <div className="mx-auto max-w-2xl px-24 lg:px-32">
          {body && (
            <div className="prose">
              {isStringHtml(body) ? (
                <div dangerouslySetInnerHTML={{ __html: body }} />
              ) : (
                <Markdown className="text-base leading-6" markdown={body} />
              )}
            </div>
          )}
          {ctaLabel && (
            <Button
              id={!idToken ? 'redeem_referral_sign_in_click' : ''}
              className="w-auto"
              type="button"
              variant="primary"
              onClick={handleButtonClick}
              loading={isButtonLoading}
            >
              {!idToken ? formatMessageAccount({ id: 'sign.in', defaultMessage: 'Sign in' }) : ctaLabel}
            </Button>
          )}
        </div>
      ) : (
        <FeedbackIconLayer loading={true} variant="secondary" />
      )}
    </>
  );
};

export default PadiRedeemReferralContent;
