import HeaderSection, { HeaderSectionProps } from 'components/padi-ui/header-section';
import { useFormat } from 'helpers/hooks/useFormat';
import { Product } from 'shared/types/product/Product';

export interface ProductDescriptionProps extends Partial<Pick<HeaderSectionProps, 'titleSize'>> {
  product: Record<string, any> | Product;
}

const ProductDescription: React.FC<ProductDescriptionProps> = ({ product, titleSize = 'text-4xl' }) => {
  const { formatMessage } = useFormat({ name: 'product' });
  const productType = product?.productType;
  let descriptionLabel: string;
  let langaugeAnchorLabel: string;
  let giftingAnchorLabel: string;

  switch (productType) {
    case 'Course':
    case 'course':
      descriptionLabel = formatMessage({ id: 'course.desc.label', defaultMessage: 'About this Course' });
      langaugeAnchorLabel = formatMessage({
        id: 'course.language.anchor.label',
        defaultMessage: 'Available Languages',
      });
      giftingAnchorLabel = formatMessage({
        id: 'course.gifting.anchor.label',
        defaultMessage: 'How to gift PADI eLearning Courses',
      });
      break;
    case 'bundle-parent':
      descriptionLabel = formatMessage({ id: 'bundle.desc.label', defaultMessage: 'About this Bundle' });
      langaugeAnchorLabel = '';
      giftingAnchorLabel = '';
      break;
    case `subscription`:
      descriptionLabel = formatMessage({ id: 'subscription.club.desc.label', defaultMessage: "What's Included" });
      langaugeAnchorLabel = '';
      giftingAnchorLabel = '';
      break;
    default:
      descriptionLabel = formatMessage({ id: 'product.desc', defaultMessage: 'About this product' });
      langaugeAnchorLabel = '';
      giftingAnchorLabel = '';
  }

  const description = product?.description;

  // let description = product?.description;

  // if (langaugeAnchorLabel) {
  //   description = `${description}<p class="prose"><a class="no-underline" href="#languages">${langaugeAnchorLabel}</a></p>`;
  // }
  // if (giftingAnchorLabel) {
  //   description = `${description}<p class="prose"><a class="no-underline" href="#gifting">${giftingAnchorLabel}</a></p>`;
  // }

  return (
    <HeaderSection
      eyebrow=""
      title={descriptionLabel}
      titleHtag="h2"
      titleSize={titleSize}
      body={description}
      bgColor="white"
      textAlign="left"
    />
  );
};

export default ProductDescription;
