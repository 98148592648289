import React, { FC } from 'react';
import { useParams } from 'next/navigation';
import Typography from 'components/commercetools-ui/atoms/typography';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import useClassNames from 'helpers/hooks/useClassNames';
import useCostsData from '../hooks/useCostsData';
import { CostsProps } from '../types';

const Costs: FC<CostsProps> = ({
  className,
  order,
  dataReference = 'cart',
  subCostsContainerClassName,
  subCostClassName,
  totalAmountClassName,
  includeFeeAndTax,
}) => {
  const { locale } = useParams();
  const { loading, costsToRender, total } = useCostsData({ dataReference, order, includeFeeAndTax });

  const totalAmountClassNames = useClassNames([
    'mt-24 mb-24 sm:mb-0 flex items-center justify-between font-medium',
    totalAmountClassName,
  ]);

  const subCostsContainerClassNames = useClassNames(['grid gap-8', subCostsContainerClassName]);
  const subCostsClassNames = useClassNames([
    'flex items-center justify-between text-secondary-black',
    subCostClassName,
  ]);

  const hasTotal = total?.value && total?.value?.centAmount !== 0 ? true : false;

  return (
    <div className={className}>
      {hasTotal && (
        <>
          <div className={subCostsContainerClassNames}>
            {costsToRender.map(({ key, label, value }) => (
              <div key={key} className={subCostsClassNames}>
                <Typography asSkeleton={loading}>{label}</Typography>
                <div>
                  <Typography asSkeleton={loading}>{CurrencyHelpers.formatForCurrency(value, locale)}</Typography>
                </div>
              </div>
            ))}
          </div>
          <div className={totalAmountClassNames}>
            <Typography asSkeleton={loading}>{total.label}</Typography>
            <div>
              <Typography asSkeleton={loading}>{CurrencyHelpers.formatForCurrency(total.value, locale)}</Typography>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Costs;
