'use client';

import Breadcrumbs, { BreadcrumbsProps } from 'components/padi-ui/breadcrumbs';
import { TasticProps } from 'frontastic/tastics/types';

const BreadcrumbsTastic = ({ data }: TasticProps<BreadcrumbsProps>) => {
  return <Breadcrumbs {...data} />;
};

export default BreadcrumbsTastic;
