'use client';

import React from 'react';
import Incentives, { IncentivesProps } from 'components/padi-ui/incentives';
import { TasticProps } from 'frontastic/tastics/types';

const IncentivesTastic = ({ data }: TasticProps<IncentivesProps>) => {
  return <Incentives incentives={data.incentives} />;
};

export default IncentivesTastic;
