'use client';
import { Product } from 'shared/types/product/Product';
import { DataSource } from 'types/datasource';
import { TasticProps } from 'frontastic/tastics/types';
import BundleTabs from 'components/padi-product/bundle-tabs';

const BundleTabsTastic = ({ data }: TasticProps<DataSource<{ product: Product }>>) => {
  const productReferences = data?.data?.dataSource?.product?.variants?.[0]?.attributes?.['product-reference'];

  const productIds: string[] = productReferences?.map(({id}: {id: string}) => id) || [];

  return <BundleTabs productIds={productIds} />;
};

export default BundleTabsTastic;
