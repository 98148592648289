import { useCallback, useEffect, useRef } from 'react';

const useTrack = () => {
  const trackRedeemReferralLink = useCallback(async (referralId: string, userSub: string) => {
    gtag('event', 'redeem_referral_link', {
      uniqueEventId: '',
      referredBy: referralId,
      referredTo: userSub,
    });
  }, []);

  return { trackRedeemReferralLink };
};

export default useTrack;
