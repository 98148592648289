'use client';

import React from 'react';
import Alert, { AlertProps } from 'components/padi-ui/alert';
import { TasticProps } from 'frontastic/tastics/types';

const AlertTastic = ({ data }: TasticProps<AlertProps>) => {
  return (
    <Alert
      body={data.body}
      alertType={data.alertType}
      label={data.label}
      links={data.links || []}
      collapsible={data.collapsible ?? false}
      accent={data.accent ?? false}
    />
  );
};

export default AlertTastic;
