'use client';

import Cta, { CtaProps } from 'components/padi-ui/cta';
import { TasticProps } from 'frontastic/tastics/types';

const CtaTastic = ({ data }: TasticProps<CtaProps>) => {
  return <Cta {...data} />;
};

export default CtaTastic;
