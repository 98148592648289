'use client';

import React from 'react';
import NotFound from 'components/commercetools-ui/organisms/not-found';

const NotFoundTastic = () => {
  return <NotFound />;
};

export default NotFoundTastic;
