import { useCallback, useEffect, useState } from 'react';
import Script from 'next/script';
import { Disclosure } from '@headlessui/react';
import { WithContext, FAQPage, Question } from 'schema-dts';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import { isStringHtml } from 'helpers/utils/isStringHtml';

export interface AccordionItem {
  label: string;
  body: string;
}

export interface AccordionProps {
  items: AccordionItem[];
  schemaType?: string;
}

const Accordion: React.FC<AccordionProps> = ({ items, schemaType }) => {
  // Json-ld schema.
  const [jsonLd, setJsonLd] = useState<WithContext<FAQPage>>();
  const jsonLdId = `jsonld-accordion-${Math.floor(Math.random() * 1000)}`;

  const setFAQPageSchema = useCallback((items: AccordionItem[]) => {
    const schemaItems: Array<Question> = [];
    const schemaData: WithContext<FAQPage> = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
    };
    items.forEach((item) => {
      schemaItems.push({
        '@type': 'Question',
        name: item.label,
        acceptedAnswer: {
          '@type': 'Answer',
          text: item.body,
        },
      });
    });
    if (schemaItems) {
      schemaData.mainEntity = schemaItems;
      setJsonLd(schemaData as WithContext<FAQPage>);
    }
  }, []);

  useEffect(() => {
    if (schemaType) {
      switch (schemaType) {
        case 'FAQPage':
          setFAQPageSchema(items);
          break;
        default:
          break;
      }
    }
  }, [items, schemaType, setFAQPageSchema]);

  return (
    <>
      {jsonLd && (
        <Script
          id={jsonLdId}
          type="application/ld+json"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
        />
      )}
      <div className="mx-auto max-w-7xl px-24 lg:px-32">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <dl className="mt-24 divide-y divide-gray-900/10 border-t border-gray-900/10">
            {items.map((item) => (
              <Disclosure as="div" key={item.label} className="">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-center justify-between py-8 text-left text-gray-900">
                        <span className="text-base font-medium leading-6">{item.label}</span>
                        {open ? (
                          <span className="material-symbols-outlined block text-4xl" aria-hidden="true">
                            expand_less
                          </span>
                        ) : (
                          <span className="material-symbols-outlined block text-4xl" aria-hidden="true">
                            expand_more
                          </span>
                        )}
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="prose max-w-none pb-14 pr-12 text-padi-gray-darker">
                      {isStringHtml(item.body) ? (
                        <div dangerouslySetInnerHTML={{ __html: item.body }} />
                      ) : (
                        <Markdown className="text-base leading-6" markdown={item.body} />
                      )}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </>
  );
};

export default Accordion;
