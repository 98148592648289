'use client';

import React from 'react';
import MenuFlyoutSimple, { MenuFlyoutSimpleProps } from 'components/padi-ui/menu/flyout-simple';
import { TasticProps } from 'frontastic/tastics/types';

const MenuFlyoutSimpleTastic = ({ data }: TasticProps<MenuFlyoutSimpleProps>) => {
  return <MenuFlyoutSimple links={data.links} align={data.align} />;
};

export default MenuFlyoutSimpleTastic;
