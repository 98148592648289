import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'next/navigation';
import { TrashIcon } from '@heroicons/react/24/outline';
import { LineItem } from 'shared/types/cart/LineItem';
import { LineItem as LineItemWishlist } from 'shared/types/wishlist/LineItem';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart, useWishlist } from 'frontastic';
import Image from 'frontastic/lib/image';
import { useTrackClickAddToCart, useTrackClickRemoveFromCart } from '../hooks/useTrack';

interface ClassNames {
  moveToWishlist?: string;
}

export interface Props {
  item: LineItem;
  classNames?: ClassNames;
}

const CartItem: React.FC<Props> = ({ item, classNames = {} }) => {
  const { locale } = useParams();
  const { removeItem, updateItem } = useCart();
  const { data: wishlist, addToWishlist } = useWishlist();

  const [processing, setProcessing] = useState(false);
  const [maxQuantityReached, setMaxQanitityReached] = useState(false);

  const { trackClickAddToCart } = useTrackClickAddToCart();
  const { trackClickRemoveFromCart } = useTrackClickRemoveFromCart();

  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  const disabledClass = 'hover:cursor-not-allowed bg-neutral-300 hover:border-neutral-400';

  const counterWrapperClassName = useClassNames([
    'flex w-fit items-center rounded-sm border border-neutral-400 transition',
    processing ? 'cursor-not-allowed bg-neutral-300' : 'cursor-pointer',
    item.disableQuantity ? disabledClass : '',
  ]);

  const counterPlusButtonClassName = useClassNames([
    'cursor-[inherit] py-3 px-10 text-secondary-black',
    maxQuantityReached ? disabledClass : '',
  ]);

  const counterCountClassName = useClassNames(['py-3 px-4 text-14 text-secondary-black']);

  const updateCartItem = useCallback(
    async (originalQuantity: number, newQuantity: number) => {
      if (processing) return;

      setProcessing(true);

      if (newQuantity < 1) await removeItem(item.lineItemId as string);
      else await updateItem(item.lineItemId as string, newQuantity);

      if (originalQuantity < newQuantity) {
        trackClickAddToCart(item);
      } else {
        trackClickRemoveFromCart(item);
      }

      setProcessing(false);
    },
    [processing, removeItem, item, updateItem, trackClickAddToCart, trackClickRemoveFromCart],
  );

  const cartLineItemToWishlistLineItem = useMemo<LineItemWishlist>(() => {
    return {
      lineItemId: item.lineItemId ?? '',
      productId: item.productId,
      name: item.name,
      type: item.type,
      count: 1,
      variant: item.variant,
      addedAt: new Date(),
      _url: item._url,
    };
  }, [item]);

  const moveToWishlist = useCallback(async () => {
    if (item.lineItemId) await removeItem(item.lineItemId);
    if (wishlist) addToWishlist(wishlist, cartLineItemToWishlistLineItem, 1);
  }, [removeItem, item.lineItemId, addToWishlist, wishlist, cartLineItemToWishlistLineItem]);

  const itemClassName = useClassNames(['flex max-w-full items-stretch justify-start gap-10 py-18 md:gap-15']);

  useEffect(() => {
    if (item?.count && item?.maxQuantity && item.count >= item.maxQuantity) {
      setMaxQanitityReached(true);
    } else {
      setMaxQanitityReached(false);
    }
  }, [item.count, item.maxQuantity]);

  return (
    <div className={itemClassName}>
      <div className="w-125 shrink-0 bg-white p-12">
        <div className="relative size-full rounded-sm">
          <Image src={item.variant?.images?.[0]} style={{ objectFit: 'contain' }} fill />
        </div>
      </div>
      <div className="max-w-full grow overflow-hidden">
        <div className="flex items-center justify-between">
          <p
            className="max-w-[85%] overflow-hidden text-ellipsis whitespace-pre text-14 leading-loose"
            title={item.name}
          >
            {item.name}
          </p>

          {!item.disableDelete && (
            <i
              onClick={() => {
                removeItem(item.lineItemId ?? '');
                trackClickRemoveFromCart(item, item.count as number);
              }}
              className="block cursor-pointer"
            >
              <TrashIcon stroke="#494949" className="w-20" />
            </i>
          )}
        </div>

        {!item.hidePrice && (
          <div className="mt-8">
            {item.discountedPrice ? (
              <div className="flex items-center gap-5">
                <span className="text-14 font-normal leading-loose text-gray-500 line-through">
                  {CurrencyHelpers.formatForCurrency(item.price ?? 0, locale)}
                </span>
                <span className="text-14 font-bold leading-loose text-accent-red">
                  {CurrencyHelpers.formatForCurrency(item.discountedPrice, locale)}
                </span>
              </div>
            ) : (
              <span className="text-14 font-medium leading-loose">
                {CurrencyHelpers.formatForCurrency(item.price ?? 0, locale)}
              </span>
            )}
          </div>
        )}

        {!item.hideQuantity && (
          <div className="mt-16">
            <div className={counterWrapperClassName}>
              <button
                onClick={() => updateCartItem(item.count as number, (item.count as number) - 1)}
                className="cursor-[inherit] px-10 py-3 text-secondary-black ltr:pl-12 rtl:pr-12"
                disabled={item.disableQuantity}
              >
                -
              </button>
              <div className={counterCountClassName}>{item.count}</div>
              <button
                onClick={() => updateCartItem(item.count as number, (item.count as number) + 1)}
                className={counterPlusButtonClassName}
                disabled={item.disableQuantity || maxQuantityReached}
              >
                +
              </button>
            </div>
          </div>
        )}

        {!item.hideWishlist && (
          <div className="mt-16 text-12">
            <p
              className={`cursor-pointer text-secondary-black decoration-secondary-black decoration-solid hover:underline hover:underline-offset-2 ${
                classNames.moveToWishlist ?? ''
              }`}
              onClick={moveToWishlist}
            >
              {formatCartMessage({ id: 'move.to.wishlist', defaultMessage: 'Move to wishlist' })}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartItem;
