'use client';

import HeaderSection, { HeaderSectionProps } from 'components/padi-ui/header-section';
import { TasticProps } from 'frontastic/tastics/types';

const HeaderSectionTastic = ({ data }: TasticProps<HeaderSectionProps>) => {
  return <HeaderSection {...data} />;
};

export default HeaderSectionTastic;
