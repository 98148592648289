'use client';

import React, { createContext, useCallback, useRef } from 'react';
import PadiHeader from 'components/padi-ui/header';
import { PadiHeaderProps } from 'components/padi-ui/header/types';
import MaintenanceBar, { Props as MaintenanceBarProps } from 'components/commercetools-ui/organisms/maintenance-bar';
import { TasticProps } from '../../types';

const PadiHeaderTastic = ({
  data,
  categories,
  searchParams,
  projectConfig,
}: TasticProps<PadiHeaderProps & MaintenanceBarProps>) => {
  const headerRef = useRef<HTMLDivElement>(null);

  const maintenanceData = {
    activateMaintenance: data.activateMaintenance,
    maintenanceText: data.maintenanceText,
  };

  return (
    <div id="padi-header-container" className="z-9 w-full" ref={headerRef}>
      {maintenanceData.activateMaintenance && <MaintenanceBar {...maintenanceData} />}
      <PadiHeader
        topbarMenus={data.topbarMenus}
        megaMenus={data.megaMenus}
        anonMenulinks={data.anonMenulinks}
        studentMenulinks={data.studentMenulinks}
        proMenulinks={data.proMenulinks}
        navLinks={categories?.filter((category) => category.depth === 0)}
        categories={categories}
        logo={data.logo}
        logoLink={data.logoLink}
        emptyCartTitle={data.emptyCartTitle}
        emptyCartSubtitle={data.emptyCartSubtitle}
        emptyCartImage={data.emptyCartImage}
        emptyCartCategories={data.emptyCartCategories}
        emptyWishlistTitle={data.emptyWishlistTitle}
        emptyWishlistSubtitle={data.emptyWishlistSubtitle}
        emptyWishlistImage={data.emptyWishlistImage}
        emptyWishlistCategories={data.emptyWishlistCategories}
        searchParams={searchParams}
        projectConfig={projectConfig}
      />
    </div>
  );
};
export default PadiHeaderTastic;
