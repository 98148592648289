import React from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import { Reference } from 'types/reference';

export interface FooterLink {
  name: string;
  reference: Reference;
}

export interface SimplifiedFooterProps {
  links?: FooterLink[];
}

const SimplifiedFooter: React.FC<SimplifiedFooterProps> = ({ links }) => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer bg-padi-footer" aria-labelledby="footer-heading">
      <div className="mx-auto max-w-7xl px-24 pb-32 pt-20 md:pt-24 lg:px-32 lg:pt-32">
        <div className="flex items-center justify-between">
          <p className=" text-xs leading-5 text-white md:mt-0">© PADI {currentYear}</p>
          <div className="flex space-x-24">
            {links?.map((link) => (
              <Link key={link.name} link={link.reference} className="text-white">
                <span className="text-xs">{link.name}</span>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SimplifiedFooter;
