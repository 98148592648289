import HeaderSection from 'components/padi-ui/header-section';

export interface ProductHeaderSectionProps {
  product: Record<string, any>;
  attributeSection: string;
  titleHtag?: string;
  titleSize:
    | 'text-xl'
    | 'text-2xl'
    | 'text-3xl'
    | 'text-4xl'
    | 'text-5xl'
    | 'text-6xl'
    | 'text-7xl'
    | 'text-8xl'
    | 'text-9xl';
  bgColor: string;
  textAlign: 'left' | 'right' | 'center';
}

const ProductHeaderSection: React.FC<ProductHeaderSectionProps> = ({
  product,
  attributeSection,
  titleHtag,
  titleSize,
  bgColor,
  textAlign,
}) => {
  try {
    const productContent = JSON.parse(product?.variants?.[0].attributes[attributeSection]);
    const eyebrow = productContent?.eyebrow;
    const title = productContent?.title;
    const body = productContent?.body;

    return (
      <HeaderSection
        eyebrow={eyebrow}
        title={title}
        titleHtag={titleHtag}
        titleSize={titleSize}
        body={body}
        bgColor={bgColor}
        textAlign={textAlign}
      />
    );
  } catch (e) {
    return <></>;
  }
};

export default ProductHeaderSection;
