import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon, CurrencyDollarIcon, CurrencyEuroIcon, CurrencyPoundIcon } from '@heroicons/react/20/solid';
import CurrencyFrancIcon from 'components/icons/currency-franc';
import { setCookie, getCookie } from 'helpers/utils/cookies';
import { ProjectCurrency } from 'frontastic/hooks/useProjectSettings/types';

export interface CurrencySelectorProps {
  currencies: Array<ProjectCurrency>;
}

const CurrencySelector: React.FC<CurrencySelectorProps> = ({ currencies }) => {
  const storedCurrencyCode = getCookie('currency');
  const storedCurrency = currencies?.find((c: ProjectCurrency) => c.code == storedCurrencyCode);
  const defaultCurrency: ProjectCurrency = {
    code: 'USD',
    name: 'US Dollar',
    symbol: '$',
    heroicon: 'currency-dollar',
    current: true,
  };
  const initialCurrency = storedCurrency || defaultCurrency;
  const [selected, setSelected] = useState(initialCurrency);

  function switchCurrencyIcon(heroIconName = 'currency-dollar', color = 'fill-black') {
    const className = `-ml-0.5 h-20 w-20 ${color}`;
    const classNameFranc = `-ml-0.5 h-20 w-20 account ${color}`;

    switch (heroIconName) {
      case 'currency-dollar':
        return <CurrencyDollarIcon className={className} aria-hidden="true" />;
      case 'currency-euro':
        return <CurrencyEuroIcon className={className} aria-hidden="true" />;
      case 'currency-pound':
        return <CurrencyPoundIcon className={className} aria-hidden="true" />;
      case 'currency-franc':
        return <CurrencyFrancIcon className={classNameFranc} aria-hidden="true" />;
      default:
        return <CurrencyDollarIcon className={className} aria-hidden="true" />;
    }
  }

  const handleChangeCurrency = (currency: ProjectCurrency) => {
    setSelected(currency);
    setCookie('currency', currency?.code ?? 'USD', 30);
    location.reload();
  };

  return (
    <div id="currency-selector" className="flex justify-end">
      <Listbox value={selected} onChange={handleChangeCurrency}>
        {({ open }) => (
          <>
            <Listbox.Label className="sr-only">Change published status</Listbox.Label>
            <div className="relative">
              <div className="inline-flex">
                <Listbox.Button className="inline-flex items-center bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50">
                  <div className="inline-flex items-center justify-between bg-white py-8 text-black">
                    {switchCurrencyIcon(selected?.heroicon, 'fill-padi-blue')}
                    <p className="pl-8 text-xs">{selected?.name}</p>
                  </div>
                  <span className="sr-only">Change published status</span>
                  <ChevronDownIcon className="h-20 w-20 text-black" aria-hidden="true" />
                </Listbox.Button>
              </div>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute right-0 z-[351] mt-0 w-288 origin-top-right overflow-hidden rounded-md bg-white shadow-lg focus:outline-none">
                  {currencies.map((option) => (
                    <Listbox.Option
                      key={option.name}
                      id={option?.code?.toLowerCase()}
                      className="cursor-pointer select-none bg-white p-16 text-sm hover:bg-padi-gray-light"
                      value={option}
                    >
                      {({ selected }) => (
                        <div className="flex flex-col">
                          <div className="flex">
                            {selected
                              ? switchCurrencyIcon(option?.heroicon, 'fill-padi-blue')
                              : switchCurrencyIcon(option?.heroicon, 'fill-black')}
                            <p className={selected ? 'ml-8 font-semibold text-padi-blue' : 'ml-8 font-normal'}>
                              {option?.name}
                            </p>
                          </div>
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default CurrencySelector;
