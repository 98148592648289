'use client';

import BentoBox, { BentoBoxProps } from 'components/padi-ui/bento-box';
import { TasticProps } from 'frontastic/tastics/types';

export type Bento33_66TasticProps = Pick<BentoBoxProps, 'promotionalTiles'> & {
  smallColumnPosition: 'left' | 'right';
};

const Bento33_66Tastic = ({ data }: TasticProps<Bento33_66TasticProps>) => {
  const gridType = data.smallColumnPosition === 'left' ? '33-66' : '66-33';

  return <BentoBox promotionalTiles={data.promotionalTiles} gridType={gridType} />;
};

export default Bento33_66Tastic;
