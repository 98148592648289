import { jwtDecode } from 'jwt-decode';
import { sdk } from 'sdk';


export interface SSOToken {
  aud: string;
  exp: number;
}

export const fetchWithIdToken = async (url: string, method: string = 'GET', body?: any) => {
  let idToken = window.localStorage.getItem('idToken');
  const user = jwtDecode<SSOToken>(idToken || '');
  const { aud, exp } = user;
  const current = Number(new Date());
  if (exp < current / 1000) {
    const payload = {
      key: 'EXTENSION_COMMERCETOOLS_COGNITO_AUTH_URL',
    };
    const res = await sdk.callAction({ actionName: 'account/getProjectConfig', payload });
    if (!res.isError) {
      const refreshToken = window.localStorage.getItem('refreshToken');
      const response = await fetch(`${(res.data as any).setting}/oauth/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientId: aud,
          idToken,
          refreshToken,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        idToken = data.idToken;
        window.localStorage.setItem('idToken', idToken || '');
        window.localStorage.setItem('refreshToken', refreshToken || '');
      }
    }
  }
  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
    method,
    body,
  });
};
