import React, { useMemo } from 'react';
import { Product } from 'shared/types/product';
import Badge from 'components/padi-ui/badge';

interface ProductBadgeProps {
  product?: Product;
}

interface BadgeType {
  text: string;
  color: 'red' | 'blue' | 'white';
}

const getBadgeData = (data: string) => {
  try {
    const { color, text: body }: BadgeType = data ? JSON.parse(data) : {};
    return { color, body };
  } catch (error) {
    console.error('Error parsing dataBadge:', error);
    return {};
  }
};

const ProductBadge: React.FC<ProductBadgeProps> = ({ product }) => {
  const dataBadge = product?.variants?.[0].attributes?.['section-14'];
  const { color, body } = useMemo(() => getBadgeData(dataBadge), [dataBadge]);

  if (!color || !body) {
    return <></>;
  }

  return (
    <div className="absolute left-12 top-12 max-w-[224px]">
      <Badge color={color} body={body} />
    </div>
  );
};

export default ProductBadge;
