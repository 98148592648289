'use client';

import React from 'react';
import FeatureSectionDynamicList, {
  FeatureSectionDynamicListProps,
} from 'components/padi-ui/feature-section/dynamic-list';
import { TasticProps } from 'frontastic/tastics/types';

const FeatureSectionDynamicListTastic = ({ data }: TasticProps<FeatureSectionDynamicListProps>) => {
  return (
    <FeatureSectionDynamicList
      title={data.title}
      titleHtag={data?.titleHtag}
      bgColor={data.bgColor}
      features={data.features}
    />
  );
};

export default FeatureSectionDynamicListTastic;
