import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Popover } from '@headlessui/react';
import { UserIcon } from '@heroicons/react/24/outline';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import AccountDropdown from 'components/commercetools-ui/organisms/account/account-atoms/account-dropdown';
import { useFormat } from 'helpers/hooks/useFormat';
import { ProjectConfig } from 'types/project-config';
import { useAccount } from 'frontastic';
import { getUserData, getUserMemberType, getUserIdToken } from 'helpers/padi/cognito';
import { ReferenceFieldLink } from 'components/padi-ui/header/types';

type Props = {
  projectConfig: ProjectConfig;
  userMenu: ReferenceFieldLink[];
};

const AccountButton = (props: Props) => {
  const projectConfig = props.projectConfig;
  const userMenu = props.userMenu;

  const { account, getProjectConfig, loggedIn } = useAccount();
  const [idToken, setIdToken] = useState('');
  const [affiliateId, setAffiliateId] = useState('');
  const [userData, setUserData] = useState<{ [key: string]: any } | undefined>(undefined);
  const [avatar, setAvatar] = useState<any>();

  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const textHello = `${account?.salutation ?? formatAccountMessage({ id: 'hello', defaultMessage: 'Hi, ' })}`;

  const title = account
    ? formatAccountMessage({ id: 'account', defaultMessage: 'Account' })
    : formatAccountMessage({ id: 'sign.in', defaultMessage: 'Login' });

  // Get users avatar.
  const getUsersAvatar = useCallback(
    async (idToken: string, affiliateId: string) => {
      if (!idToken) return;
      const proApiDomain = await getProjectConfig('EXTENSION_PADI_APIS_PRO_API_DOMAIN');
      if (proApiDomain.isError) return;
      const getPhoto = await fetch(`${proApiDomain.setting}/certifications/photos/getphoto`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ affiliateId: affiliateId }),
        method: 'post',
      })
        .then((response) => response.blob())
        .then((data) => {
          const objectUrl = URL.createObjectURL(data);
          if (data?.size !== 0) setAvatar(objectUrl);
        });
    },
    [idToken, affiliateId],
  );

  useEffect(() => {
    const getIdToken = getUserIdToken();
    if (getIdToken) {
      setIdToken(getIdToken);
    }

    const cognitoUserData = getUserData();
    if (cognitoUserData) {
      setUserData(cognitoUserData);
      setAffiliateId(cognitoUserData['custom:affiliate_id']);
    }

    if (getIdToken && cognitoUserData) {
      getUsersAvatar(getIdToken, cognitoUserData['custom:affiliate_id']);
    }
  }, [getUserIdToken, getUserData]);

  return (
    <div className="h-40">
      <Link link={account ? '/account' : '/login'} title={title}>
        <UserIcon className="hidden h-fit w-28 text-secondary-black md:flex lg:hidden" />
      </Link>
      <Popover as="div" className="relative hidden h-fit lg:block">
        {() => (
          <>
            <Popover.Button title={title}>
              <div className="flex w-fit whitespace-nowrap">
                <div className="mr-8 hidden w-104 py-4 lg:inline-block">
                  {userData && (
                    <Typography className="hidden truncate text-secondary-black lg:block">{`${textHello} ${userData?.given_name}`}</Typography>
                  )}
                </div>

                <div className="h-28 w-28 border-primary-black pb-8 hover:border-b-2">
                  {avatar ? (
                    <img className="h-28 w-28 rounded-full object-cover" src={avatar} />
                  ) : (
                    <UserIcon className="w-28 text-secondary-black" />
                  )}
                </div>
              </div>
            </Popover.Button>
            <Popover.Overlay className="fixed inset-0 z-[310] bg-secondary-black opacity-30" />
            <Popover.Panel className="absolute left-15 top-50 z-[310] animate-[appearDropdown_0.15s_ease-in-out] rounded-sm bg-white shadow-400">
              <div className="absolute -top-20 left-1/2 z-10 w-31 -translate-x-1/2 overflow-hidden">
                <div className="h-21 w-21 origin-bottom-left rotate-45 bg-white" />
              </div>
              <AccountDropdown loggedIn={loggedIn} projectConfig={projectConfig} userMenu={userMenu} />
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  );
};

export default AccountButton;
