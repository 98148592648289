export const setCookie = (name: string, value: string, days: number) => {
  const now = new Date();
  now.setTime(now.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + now.toUTCString();
  const domain = window.location.host;
  document.cookie = name + '=' + value + ';' + expires + ';' + domain + ';path=/';
};

export const getCookie = (name: string) => {
    const value = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)');
    return value ? value.pop() : null;
};
