'use client';

import React from 'react';
import Referral from 'components/padi-ui/referral';

const ReferralRedeemTastic = () => {
  return <Referral />;
};

export default ReferralRedeemTastic;
