export const PLP_PRODUCT_CLICKED = 'PLP: Product Clicked';
export const PLP_PRODUCT_ADDED_TO_CART = 'PLP: Product Added To Cart';
export const SLIDER_PRODUCT_CLICKED = 'Slider: Product Clicked';
export const PDP_PRODUCT_ADDED_TO_CART_AFTER_SEARCH = 'PDP: Product Added To Cart After Search';
export const PDP_PRODUCT_ADDED_TO_CART = 'PDP: Product Added To Cart';
export const PDP_PRODUCT_CONTACT_DIVESHOP = 'pdp-contact-dive-shop';
export const PDP_PRODUCT_CONTACT_DIVESHOP_AFTER_SEARCH = 'pdp-contact-dive-shop-after-search';
export const QUICK_VIEW_PRODUCT_CONTACT_DIVESHOP = 'quickview-product-contact-dive-shop';
export const QUICK_VIEW_PRODUCT_CONTACT_DIVESHOP_AFTER_SEARCH = 'quickview-product-contact-dive-shop-after-search';
export const PRODUCT_VIEWED = 'Product Viewed';
export const PDP_VIEWED = 'Product Details Page Viewed';
export const PDP_VIEWED_AFTER_SEARCH = 'Product Details Page Viewed After Search';
export const QUICK_VIEW_PRODUCT_ADDED_TO_CART = 'Quickview: Product Added To Cart';
export const QUICK_VIEW_PRODUCT_ADDED_TO_CART_AFTER_SEARCH = 'Quickview: Product Added To Cart After Search';
export const AUTOCOMPLETE_PRODUCT_CLICKED = 'Autocomplete: Product Clicked';
export const FILTER_APPLIED = 'Filter Applied';
export const VIEW_CART = 'view_cart';
export const ADD_TO_CART = 'add_to_cart';
export const REMOVE_FROM_CART = 'remove_from_cart';
export const DEFAULT_CURRENCY = 'USD';
export const PADI_CHECKOUT_NAME = 'Commerce Tools FE';
export const PADI_BRAND = 'PADI';
export const BEGIN_CHECKOUT = 'begin_checkout';
export const PURCHASE = 'purchase';
export const ADD_PAYMENT_INFO = `add_payment_info`;
export const EVENT_ITEM_CATEGORY_COURSES = 'elearning course';
export const EVENT_ITEM_CATEGORY_CLUB = 'PADI Club';
export const EVENT_ITEM_CATEGORY_CERT_CARDS = 'Certification Cards';
export const EVENT_ITEM_CATEGORY_BUNDLES = 'Bundles';
export const EVENT_ITEM_CATEGORY_DONATION = 'Donation';
