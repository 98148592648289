import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg
    className={className}
    width="100%"
    height="100%"
    version="1.1"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m50 10.418c-10.5 0-20.566 4.168-27.988 11.594-7.4258 7.4219-11.594 17.488-11.594 27.988s4.168 20.566 11.594 27.988c7.4219 7.4258 17.488 11.594 27.988 11.594s20.566-4.168 27.988-11.594c7.4258-7.4219 11.594-17.488 11.594-27.988s-4.168-20.566-11.594-27.988c-7.4219-7.4258-17.488-11.594-27.988-11.594zm10.418 27.082h-12.5c-1.1523 0-2.0859 0.93359-2.0859 2.082v8.332h12.5v0.003907c1.1523 0 2.0859 0.92969 2.0859 2.082s-0.93359 2.082-2.0859 2.082h-12.5v4.168h8.3359c1.1484 0 2.082 0.93359 2.082 2.082 0 1.1523-0.93359 2.0859-2.082 2.0859h-8.3359v6.25c0 1.1484-0.92969 2.082-2.082 2.082s-2.082-0.93359-2.082-2.082v-6.25h-2.0859c-1.1484 0-2.082-0.93359-2.082-2.0859 0-1.1484 0.93359-2.082 2.082-2.082h2.082l0.003907-16.668c0-3.4492 2.7969-6.25 6.25-6.25h12.5c1.1484 0 2.082 0.93359 2.082 2.0859 0 1.1484-0.93359 2.082-2.082 2.082z" />
  </svg>
);

export default Icon;
