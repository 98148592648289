import Link from 'components/commercetools-ui/atoms/link';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import Image, { ImageProps } from 'frontastic/lib/image';
import { isStringHtml } from 'helpers/utils/isStringHtml';
import { useMemo } from 'react';
import { Reference } from 'types/reference';

export interface CategoryPreviewsProps {
  headingTextSize?: string;
  headingText: string;
  bodyText?: string;
  bodyTextSize?: string;
  ctaText?: string;
  ctaLink?: Reference;
  image: ImageProps;
  imagePosition: string;
  backgroundColor: string;
  buttonVariant: 'primary' | 'secondary';
}

const CategoryPreviews: React.FC<CategoryPreviewsProps> = ({
  imagePosition,
  image,
  ctaLink,
  ctaText,
  headingTextSize,
  headingText,
  bodyTextSize,
  bodyText,
  backgroundColor,
  buttonVariant = 'primary',
}) => {
  const headingClasses = useMemo(() => {
    return `text-${headingTextSize} font-bold text-white`;
  }, [headingTextSize]);

  const bodyClasses = useMemo(() => {
    return `text-${bodyTextSize} mt-1 text-gray-300`;
  }, [bodyTextSize]);

  const contextBoxClasses = useMemo(() => {
    const boxPosition = imagePosition === 'left' ? '' : 'lg:right-0';
    return `absolute inset-x-0 bottom-0 rounded-bl-lg rounded-br-lg bg-black bg-opacity-75 p-[24px] backdrop-blur backdrop-filter sm:flex sm:items-center sm:justify-between lg:inset-x-auto lg:inset-y-0 lg:w-[384px] lg:flex-col lg:items-start lg:rounded-br-none lg:rounded-tl-lg ${boxPosition}`;
  }, [imagePosition]);

  const buttonBgColor = useMemo(() => {
    return buttonVariant === 'primary'
      ? 'bg-blue-500 hover:bg-blue-700'
      : 'border border-white border-opacity-25 bg-white bg-opacity-0 hover:bg-opacity-10';
  }, [buttonVariant]);

  return (
    <div className={`bg-${backgroundColor}`}>
      <div className="mx-auto max-w-[672px] px-[16px] py-[64px] sm:px-6 sm:py-[96px] lg:max-w-[1280px]">
        <div className="relative overflow-hidden rounded-lg lg:h-[386px]">
          <div className="absolute inset-0">
            <Image
              className="h-full w-full object-cover object-center"
              media={image.media}
              alt={image.title}
              priority
            />
          </div>
          <div aria-hidden="true" className="relative h-[386px] w-full lg:hidden" />
          <div aria-hidden="true" className="relative h-[128px] w-full lg:hidden" />
          <div className={contextBoxClasses}>
            <div>
              <h2 className={headingClasses}>{headingText}</h2>
              {bodyText && isStringHtml(bodyText) ? (
                <div className={bodyClasses} dangerouslySetInnerHTML={{ __html: bodyText }} />
              ) : (
                bodyText && <Markdown className={bodyClasses} markdown={bodyText} />
              )}
            </div>
            {ctaLink && ctaText && (
              <Link
                className={`mt-24 flex h-40 flex-shrink-0 items-center justify-center rounded-md px-[16px] py-[12px] text-base font-medium text-white sm:ml-8 sm:mt-0 lg:ml-0 lg:w-full ${buttonBgColor}`}
                link={ctaLink}
              >
                {ctaText}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryPreviews;
