'use client';

import { Product } from 'shared/types/product/Product';
import ProductCtaAction, { ProductCtaActionProps } from 'components/padi-product/cta-action';
import { DataSource } from 'types/datasource';
import { TasticProps } from 'frontastic/tastics/types';

const ProductCtaActionTastic = ({ data }: TasticProps<DataSource<{ product: Product }> & ProductCtaActionProps>) => {
  if (!data?.data?.dataSource?.product) return null;

  return (
    <ProductCtaAction
      product={data?.data?.dataSource.product}
      titleHtag={data?.titleHtag}
      attributeSection={data?.attributeSection}
      bgColor={data?.bgColor}
      image={data?.image}
      imagePosition={data?.imagePosition}
    />
  );
};

export default ProductCtaActionTastic;
