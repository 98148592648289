import Image from 'frontastic/lib/image';

export interface IncentivesProps {
  incentives: object;
}

const Incentives: React.FC<IncentivesProps> = ({ incentives }) => {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
        <div className="mt-16 grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
          {Object.values(incentives).map((incentive) => (
            <div key={incentive.title} className="sm:flex lg:block">
              <div className="flex items-center justify-center py-40 sm:shrink-0">
                <Image
                  {...incentive.image}
                  priority
                  loading="eager"
                  alt={incentive.title}
                  style={{ objectFit: 'cover', objectPosition: 'center' }}
                  className="h-80 w-80"
                />
              </div>
              <div className="mt-4 text-center sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                <div className="pb-10 font-bold">{incentive.title}</div>
                <p className="mt-2 text-sm">{incentive.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Incentives;
