'use client';

import Hero, { HeroProps } from 'components/commercetools-ui/organisms/content/hero';
import { TasticProps } from 'frontastic/tastics/types';

const HeroTastic = ({ data }: TasticProps<HeroProps>) => {
  return <Hero {...data} />;
};

export default HeroTastic;
