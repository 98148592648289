import React, { useCallback } from 'react';
import { UserIcon } from '@heroicons/react/24/outline';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import useGetUserInfo from 'helpers/hooks/useGetUserInfo';
import { Popover } from '@headlessui/react';
import { useAccount } from 'frontastic';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { deleteCognitoCookies } from 'helpers/padi/cognito';

const User: React.FC = () => {
  const { avatar, userData } = useGetUserInfo();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const textHello = formatAccountMessage({ id: 'hello', defaultMessage: 'Hi, ' });
  const { logout } = useAccount();
  const router = useRouter();
  const pathname = usePathname();
  const { locale } = useParams();

  const handleLogout = useCallback(() => {
    logout()
      .then(() => deleteCognitoCookies())
      .then(() => (pathname.endsWith('courses/') ? window.location.reload() : router.push(`/${locale}/courses`)));
  }, [locale, pathname, logout]);

  return (
    <div className="flex w-full justify-end xl:w-fit">
      {userData ? (
        <>
          <div className="mr-8 w-104 py-4 lg:inline-block">
            <Typography className=" truncate text-secondary-black lg:block">{`${textHello} ${userData?.given_name}`}</Typography>
          </div>
          <Popover className="relative  block h-fit">
            <Popover.Button title={'Popover Title'}>
              <div className="h-28 w-28 border-primary-black pb-8 hover:border-b-2">
                {avatar ? (
                  <img className="h-28 w-28 rounded-full object-cover" src={avatar} />
                ) : (
                  <UserIcon className="w-28 text-secondary-black" />
                )}
              </div>
            </Popover.Button>
            <Popover.Overlay className="fixed inset-0 z-[310] bg-secondary-black opacity-30" />
            <Popover.Panel className="absolute -left-85 top-50 z-[310] animate-[appearDropdown_0.15s_ease-in-out] rounded-sm bg-white shadow-400 md:-left-45">
              <div className="absolute -top-20 left-1/2 z-10 w-31 -translate-x-1/2 overflow-hidden">
                <div className="h-21 w-21 origin-bottom-left rotate-45 bg-white" />
              </div>
              <Popover.Button className="block w-full">
                <div className="w-120 p-14">
                  <Typography className="text-16 text-primary-black hover:underline" onClick={handleLogout}>
                    {formatAccountMessage({ id: 'sign.out', defaultMessage: 'Sign out' })}
                  </Typography>
                </div>
              </Popover.Button>
            </Popover.Panel>
          </Popover>
        </>
      ) : (
        <UserIcon className="w-28 text-secondary-black" />
      )}
    </div>
  );
};
export default User;
