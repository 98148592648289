'use client';

import React from 'react';
import Stats, { StatsProps } from 'components/padi-ui/stats';
import { TasticProps } from 'frontastic/tastics/types';

const StatsTastic = ({ data }: TasticProps<StatsProps>) => {
  return <Stats bgColor={data.bgColor} stats={data.stats} />;
};

export default StatsTastic;
