import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import Button from 'components/commercetools-ui/atoms/button';
import Drawer from 'components/commercetools-ui/atoms/drawer';
import Typography from 'components/commercetools-ui/atoms/typography';
import { MegaMenu, ReferenceFieldLink } from 'components/padi-ui/header/types';
import { useFormat } from 'helpers/hooks/useFormat';
import { getUserData, getUserIdToken } from 'helpers/padi/cognito';
import { ProjectConfig } from 'types/project-config';
import { Reference } from 'types/reference';
import { useAccount } from 'frontastic';
import { ImageProps } from 'frontastic/lib/image';
import MobileMenu from './content/mobile-menu';
import MobileMenuFooter from './content/mobile-menu-footer';
import MobileMenuHeader from './content/mobile-menu-header';

export interface Props {
  logo: ImageProps;
  logoLink: Reference;
  megaMenus: MegaMenu[];
  userMenu: ReferenceFieldLink[];
  topbarMenus?: ReferenceFieldLink[];
  projectConfig: ProjectConfig;
}

const HeaderNavigationMobile: FC<Props> = ({ logo, logoLink, megaMenus, userMenu, topbarMenus, projectConfig }) => {
  const { account, getProjectConfig, loggedIn } = useAccount();
  const [megaMenuNavigator, setMegaMenuNavigator] = useState<MegaMenu[]>([]);
  const [showMenu, setShowMenu] = useState(false);
  const [idToken, setIdToken] = useState('');
  const [affiliateId, setAffiliateId] = useState('');
  const [userData, setUserData] = useState<{ [key: string]: any } | undefined>(undefined);
  const [avatar, setAvatar] = useState<any>();

  const { formatMessage } = useFormat({ name: 'common' });

  // Get users avatar.
  const getUsersAvatar = useCallback(
    async (idToken: string, affiliateId: string) => {
      if (!idToken) return;
      const proApiDomain = await getProjectConfig('EXTENSION_PADI_APIS_PRO_API_DOMAIN');
      if (proApiDomain.isError) return;
      const getPhoto = await fetch(`${proApiDomain.setting}/certifications/photos/getphoto`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ affiliateId: affiliateId }),
        method: 'post',
      })
        .then((response) => response.blob())
        .then((data) => {
          const objectUrl = URL.createObjectURL(data);
          if (data?.size !== 0) setAvatar(objectUrl);
        });
    },
    [idToken, affiliateId],
  );

  useEffect(() => {
    const getIdToken = getUserIdToken();
    if (getIdToken) setIdToken(getIdToken);

    const cognitoUserData = getUserData();
    if (cognitoUserData) {
      setUserData(cognitoUserData);
      setAffiliateId(cognitoUserData['custom:affiliate_id']);
    }

    if (getIdToken && cognitoUserData) getUsersAvatar(getIdToken, cognitoUserData['custom:affiliate_id']);
  }, []);

  const showHeaderMenu = () => {
    setShowMenu(true);
  };

  const hideHeaderMenu = () => {
    setShowMenu(false);
    setMegaMenuNavigator([]);
  };

  const removeMegaMenu = () => {
    setMegaMenuNavigator((array) => array.slice(0, -1));
  };

  const insertMegaMenu = (megaMenu: MegaMenu) => {
    setMegaMenuNavigator((array) => [...array, megaMenu]);
  };

  return (
    <div className="flex xl:hidden">
      <Button
        variant="ghost"
        size="fit"
        onClick={showHeaderMenu}
        title={formatMessage({ id: 'header.menu.open', defaultMessage: 'Open side menu' })}
        className="mr-8"
      >
        <Bars3Icon className="w-30 text-secondary-black lg:w-48" />
      </Button>

      <Drawer
        isOpen={showMenu}
        direction="left"
        className="w-4/5 overflow-y-scroll border border-neutral-400 bg-neutral-100"
        onClose={hideHeaderMenu}
      >
        <MobileMenuHeader
          megaMenus={megaMenuNavigator}
          hideHeaderMenu={hideHeaderMenu}
          logo={logo}
          logoLink={logoLink}
          onArrowClick={removeMegaMenu}
        />

        <MobileMenu
          hideHeaderMenu={hideHeaderMenu}
          megaMenuNavigator={megaMenuNavigator}
          insertMegaMenu={insertMegaMenu}
          megaMenus={megaMenus}
        />

        <div className="mb-16 mt-12 w-full border-b-[1.5px] border-neutral-400" />

        {userData && (
          <div className="mx-24 py-8">
            <div className="flex w-fit whitespace-nowrap">
              <div className="h-28 w-28 border-primary-black hover:border-b-2">
                {avatar && <img className="h-28 w-28 rounded-full object-cover" src={avatar} />}
              </div>
              <div className="ml-10 w-auto py-4">
                <Typography className="truncate text-secondary-black lg:block">{`${userData?.given_name} ${userData?.family_name}`}</Typography>
              </div>
            </div>
          </div>
        )}

        <MobileMenuFooter
          showMenu={showMenu}
          hideHeaderMenu={hideHeaderMenu}
          userMenu={userMenu}
          topbarMenus={topbarMenus}
          projectConfig={projectConfig}
        />
      </Drawer>
    </div>
  );
};

export default HeaderNavigationMobile;
