import { useCallback, useMemo } from 'react';
import { Product } from 'shared/types/product/Product';
import Image from 'frontastic/lib/image';

export interface ProductCardProps {
  product: Product;
  selectProduct: (product: Product) => void;
  isSelected: boolean;
}

const ProductCard: React.FC<ProductCardProps> = ({ product, selectProduct, isSelected }) => {
  const handleClick = useCallback(() => {
    selectProduct(product);
  }, [selectProduct, product]);

  const image = useMemo(() => {
    if (!product.variants?.[0]) {
      return null;
    }
    return (
      <Image
        src={product.variants[0]?.images?.[0]}
        alt={product.name}
        className="aspect-video h-320 w-full object-cover object-center group-hover:opacity-75 lg:w-full"
      />
    );
  }, [product]);

  let warperStyle = 'relative mx-auto h-320 w-full cursor-pointer overflow-hidden rounded-md';
  if (isSelected) {
    warperStyle += ' border-solid border-2 border-white';
  }
  return (
    <div className={warperStyle} onClick={handleClick}>
      {image}
      <div className="box-border·h-96 absolute bottom-0 left-0 right-0 rounded-b-md bg-emerald-950 bg-opacity-60 p-16 text-white backdrop-blur">
        <h4 className="text-20 font-medium text-white">{product?.name}</h4>
        <p className="mt-8 text-16 font-semibold text-white">View Details</p>
      </div>
    </div>
  );
};

export default ProductCard;
