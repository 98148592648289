import FeatureSectionCheckList from 'components/padi-ui/feature-section/check-list';

export interface PadiProductFeatureSectionCheckListProps {
  product: Record<string, any>;
  titleHtag?: string;
  attributeSection: string;
  bgColor: string;
  fgColor: string;
}

const PadiProductFeatureSectionCheckList: React.FC<PadiProductFeatureSectionCheckListProps> = ({
  product,
  titleHtag,
  attributeSection,
  bgColor,
  fgColor,
}) => {
  try {
    const productContent = JSON.parse(product?.variants?.[0].attributes[attributeSection]);
    const title = productContent?.title ?? '';
    const features = productContent?.features ?? [];

    return (
      <FeatureSectionCheckList
        title={title}
        titleHtag={titleHtag}
        bgColor={bgColor}
        fgColor={fgColor}
        features={features}
      />
    );
  } catch (e) {
    return <></>;
  }
};

export default PadiProductFeatureSectionCheckList;
