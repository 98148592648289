'use client';

import PadiDiveShopHeader, { PadiDiveShopHeaderProps } from 'components/padi-dive-shop/dive-shop-header';
import { TasticProps } from 'frontastic/tastics/types';

const PadiDiveShopHeaderTastic = ({ data, searchParams, projectConfig }: TasticProps<PadiDiveShopHeaderProps>) => {
  return (
    <PadiDiveShopHeader
      bgColor={data?.bgColor}
      textAlign={data?.textAlign}
      searchParams={searchParams}
      projectConfig={projectConfig}
    />
  );
};

export default PadiDiveShopHeaderTastic;
