import Stats from 'components/padi-ui/stats';

export interface PadiProductStatsProps {
  product: Record<string, any>;
  attributeSection: string;
  bgColor: string;
}
const PadiProductStats: React.FC<PadiProductStatsProps> = ({ product, attributeSection, bgColor }) => {
  try {
    const productContent = JSON.parse(product?.variants?.[0].attributes[attributeSection]);
    const stats = productContent?.stats ?? [];

    return <Stats bgColor={bgColor} stats={stats} />;
  } catch (e) {
    return <></>;
  }
};

export default PadiProductStats;
