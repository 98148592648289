'use client';

import PadiRedeemReferralContent, {
  PadiRedeemReferralContentProps,
} from 'components/padi-redeem-referral/redeem-content';
import { TasticProps } from 'frontastic/tastics/types';

const PadiRedeemReferralContentTastic = ({ data, projectConfig }: TasticProps<PadiRedeemReferralContentProps>) => {
  return <PadiRedeemReferralContent body={data?.body} ctaLabel={data?.ctaLabel} projectConfig={projectConfig} />;
};

export default PadiRedeemReferralContentTastic;
