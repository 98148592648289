'use client';

import { useEffect, useCallback, useState } from 'react';
import { useParams, useRouter, useSearchParams } from 'next/navigation';
import { Cart } from 'shared/types/cart';
import countries from 'shared/types/countries.json';
import { mutate } from 'swr';
import Button from 'components/commercetools-ui/atoms/button';
import Dropdown from 'components/commercetools-ui/atoms/dropdown';
import Modal from 'components/commercetools-ui/atoms/modal';
import Alert from 'components/padi-ui/alert';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import usePath from 'helpers/hooks/usePath';
import { getLocalStorage, setLocalStorage } from 'helpers/utils/localStorage';
import { desktop } from 'helpers/utils/screensizes';
import { getTranslationLanguages, getLocaleByZone } from 'project.config';
import { sdk } from 'sdk';
import { getZoneKeyByCountryCode, useAccount } from 'frontastic/hooks';
import Image from 'frontastic/lib/image';
export interface PadiGeolocationModalProps {
  isOpen?: boolean;
  syncIsOpen?: any;
}

interface SelectOption {
  label: string;
  value: string;
}

const PadiGeolocationModal: React.FC<PadiGeolocationModalProps> = ({ isOpen = false, syncIsOpen }) => {
  const { locale: urlLocale } = useParams();
  const { getProjectConfig } = useAccount();
  const [isDesktopSize] = useMediaQuery(desktop);
  const { path } = usePath();
  const router = useRouter();
  const searchParams = useSearchParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLocaleStored, setIsLocaleStored] = useState<boolean>(false);
  const [countryOptions, setCountryOptions] = useState<SelectOption[]>();
  const [languageOptions, setLanguageOptions] = useState<SelectOption[]>();
  const [selectedCountry, setSelectedCountry] = useState<string>('us');
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en');
  const [geoCountry, setGeoCountry] = useState<string>();
  const [storedCountry, setStoredCountry] = useState<string>();
  const [isCartEmpty, setIsCartEmpty] = useState(true);

  const { formatMessage: formatMessagePadi } = useFormat({ name: 'padi' });

  const loadModal = useCallback(() => {
    setIsModalOpen(true);
    if (!countryOptions) getCountries();
    if (!languageOptions) getLanguages();
  }, [countryOptions, languageOptions]);

  const closeModal = () => {
    setIsModalOpen(false);
    syncIsOpen(false);
    setIsLoading(false);
  };

  const getSetIsCartEmpty = useCallback(async () => {
    await sdk
      .callAction({ actionName: 'cart/getCart' })
      .then((response) => {
        if (!response.isError) {
          return response.data as Cart;
        }
      })
      .then((data) => {
        if (data?.lineItems?.length) {
          setIsCartEmpty(false);
        }
      });
  }, []);

  const deleteCart = async () => {
    await sdk.callAction({ actionName: 'cart/deleteCart' });
    await mutate('/action/cart/getCart');
  };

  const handleCountryChange = async (e: React.FormEvent) => {
    const value = (e.target as HTMLSelectElement).value;
    setSelectedCountry(value);
  };

  const handleLanguageChange = async (e: React.FormEvent) => {
    const value = (e.target as HTMLSelectElement).value;
    setSelectedLanguage(value);
  };

  const handleSaveLocale = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsLoading(true);
    const country = countries.find((c) => c.twoLetterISORegionName === selectedCountry.toUpperCase());
    const currentZone = getLocalStorage('padiUserZone');
    const zone: any = await getZoneKeyByCountryCode(
      country?.mappingCode ? country.mappingCode.toUpperCase() : selectedCountry.toUpperCase(),
    );

    if (zone) {
      const newLocaleObject = getLocaleByZone(selectedLanguage, zone);
      const newLocale = newLocaleObject?.locale;
      const newZone = newLocaleObject?.zone;
      const isCheckout = path.includes('/checkout');
      if (newLocale) {
        if (newLocale != urlLocale || isCheckout) {
          if (newZone != currentZone || isCheckout) {
            deleteCart();
          }
          const host = window.location.origin;
          const query = searchParams ? `?${searchParams}` : '';
          const url = `${host}/${newLocale}${path}${query}`;
          setLocalStorage('padiUserLocale', newLocale);
          setLocalStorage('padiUserZone', newZone);
          setLocalStorage('padiUserCountry', selectedCountry);
          setIsLocaleStored(true);
          router.push(isCheckout ? `/${newLocale}/courses` : url);
        } else {
          if (!isLocaleStored) {
            setLocalStorage('padiUserLocale', newLocale);
            setLocalStorage('padiUserZone', newZone);
          }
          setLocalStorage('padiUserCountry', selectedCountry);
          setIsLocaleStored(true);
          closeModal();
        }
      }
    }
  };

  const getCountries = async () => {
    const options: SelectOption[] = [];
    countries.map((country: { englishName: string; twoLetterISORegionName: string }) => {
      const label = country?.englishName;
      const value = country?.twoLetterISORegionName.toLowerCase();
      options.push({
        label,
        value,
      });
    });
    setCountryOptions(options);
    setIsLoading(false);
  };

  const getLanguages = async () => {
    const languages = getTranslationLanguages();
    const options: SelectOption[] = [];
    for (const [key, lang] of Object.entries(languages as { [key: string]: any })) {
      const label = lang?.nativeName;
      const value = lang?.twoDigitCode;
      options.push({
        label,
        value,
      });
    }

    setLanguageOptions(options);
  };

  const getGeolocation = useCallback(async () => {
    const geolocationEndpoint = await getProjectConfig('EXTENSION_PADI_APIS_GEOLOCATION_API_DOMAIN');
    const response = await fetch(geolocationEndpoint?.setting);
    if (response.ok) {
      const data = await response.json();
      setGeoCountry(data.countryIsoCode);
    }
  }, [getProjectConfig]);

  useEffect(() => {
    getGeolocation();
    getSetIsCartEmpty();
  }, [getGeolocation, getSetIsCartEmpty]);

  useEffect(() => {
    const disablePages = ['/checkout', '/thank-you'];
    disablePages.map((page) => {
      if (path.startsWith(page)) return;
    });

    const userLocale = getLocalStorage('padiUserLocale');
    const urlLocaleParts = urlLocale.split('-');
    const userCountry = getLocalStorage('padiUserCountry');
    if (userCountry) setStoredCountry(userCountry);
    if (geoCountry) {
      // Non-us & no locale stored.
      if (!userLocale && geoCountry != 'US') {
        setSelectedCountry(geoCountry.toLowerCase());
        setSelectedLanguage(urlLocaleParts[0]);
        loadModal();
      }
    }
    if (!userLocale && geoCountry == 'US') {
      setLocalStorage('padiUserLocale', 'en-us');
      setLocalStorage('padiUserZone', 'zone-1');
      setLocalStorage('padiUserCountry', 'us');
      syncIsOpen(false);
    }
    if (userLocale) {
      const storedLocale = userLocale.split('-');
      // Url locale != stored locale.
      if (userLocale != urlLocale) {
        setSelectedCountry(userCountry);
        setSelectedLanguage(urlLocaleParts[0]);
        loadModal();
      } else {
        setSelectedCountry(userCountry);
        setSelectedLanguage(storedLocale[0]);
        setIsLocaleStored(true);
      }
    }
    if (isOpen) {
      loadModal();
    }
  }, [isOpen, geoCountry, urlLocale, path, loadModal, syncIsOpen]);

  return (
    <>
      <div>
        <Modal
          isOpen={isModalOpen}
          closeButton={isLocaleStored}
          shouldCloseOnOverlayClick={isLocaleStored}
          shouldCloseOnEsc={isLocaleStored}
          onRequestClose={closeModal}
          style={{ content: { background: 'white', border: 'none', maxWidth: isDesktopSize ? 600 : '90%' } }}
          closeTimeoutMS={200}
        >
          <div className="p-20 md:px-40">
            <div className="flex w-full flex-col p-20 text-center">
              <div className="mb-10 text-3xl" tabIndex={0}>
                {formatMessagePadi({ id: 'welcome.to', defaultMessage: 'Welcome To' })}
              </div>
              <div className="relative h-60">
                <Image
                  src="https://res.cloudinary.com/dlwdq84ig/image/upload/w_auto,q_auto/v1710348174/ovn5hasieenddx4ofja4.svg"
                  fill
                  style={{ objectFit: 'contain' }}
                  alt="Professional Association of Diving Instructors logo"
                  priority
                  tabIndex={0}
                />
              </div>
            </div>
            <div className="flex flex-col pt-20 md:px-40">
              <p tabIndex={0}>
                {formatMessagePadi({
                  id: 'select.your.country.language.for.best.experience',
                  defaultMessage: 'Select your country and language for the best experience.',
                })}
              </p>
              {!isCartEmpty ? (
                <div tabIndex={0}>
                  <Alert
                    alertType="warning"
                    body={`${formatMessagePadi({
                      id: 'change.country.alert',
                      defaultMessage:
                        'Updating your country may change the currency and price displayed on the website and remove any items in your cart.',
                    })}`}
                  />
                </div>
              ) : (
                <div className="my-10"></div>
              )}
              <div className="mb-20 flex flex-col">
                <label className="font-medium" tabIndex={0}>
                  {formatMessagePadi({ id: 'country', defaultMessage: 'Country' })}
                </label>
                <Dropdown
                  className="h-full rounded-sm"
                  defaultValue={storedCountry ?? selectedCountry}
                  items={countryOptions}
                  onChange={handleCountryChange}
                  tabIndex={0}
                />
              </div>
              <div className="mb-20 flex flex-col">
                <label className="font-medium" tabIndex={0}>
                  {formatMessagePadi({ id: 'language', defaultMessage: 'Language' })}
                </label>
                <Dropdown
                  className="h-full rounded-sm"
                  value={selectedLanguage}
                  items={languageOptions}
                  onChange={handleLanguageChange}
                  tabIndex={0}
                />
              </div>
              <div className="flex justify-center">
                <Button
                  type="button"
                  className="w-full uppercase md:w-auto"
                  onClick={handleSaveLocale}
                  loading={isLoading}
                  tabIndex={0}
                >
                  {formatMessagePadi({ id: 'ok', defaultMessage: 'Ok' })}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default PadiGeolocationModal;
