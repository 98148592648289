import { jwtDecode } from 'jwt-decode';
import { isDevelopment } from 'helpers/utils/environment';
import { getLocalStorage, setLocalStorage } from 'helpers/utils/localStorage';

interface Cookies {
  [key: string]: string;
}

export interface CognitoUserData {
  [key: string]: any;
}

export const getLocalIdToken = () => {
  const idToken = getLocalStorage('idToken');
  if (idToken) return idToken;
  return null;
};

export const getLocalUserData = () => {
  const idToken = getLocalIdToken();
  if (idToken) {
    return jwtDecode<CognitoUserData>(idToken);
  }
  return null;
};

export const getCognitoCookies = () => {
  const cookies: Cookies = {};
  if (document.cookie && document.cookie !== '') {
    const split = document.cookie.split(';');
    for (let i = 0; i < split.length; i++) {
      const name = split[i].split('=');
      name[0] = name[0].replace(/^ /, '');
      if (name[0].startsWith('CognitoIdentityServiceProvider')) {
        cookies[decodeURIComponent(name[0])] = decodeURIComponent(name[1]);
      }
    }
  }
  return cookies;
};

const parseCognitoCookies = () => {
  const data = {
    idToken: '',
    accessToken: '',
    refreshToken: '',
    userData: '',
    LastAuthUser: '',
    clientId: '',
  };
  const cognitoCookies = getCognitoCookies();
  if (cognitoCookies) {
    for (const [key, value] of Object.entries(cognitoCookies)) {
      if (key.endsWith('idToken')) {
        data.idToken = value;
      }
      if (key.endsWith('accessToken')) {
        data.accessToken = value;
      }
      if (key.endsWith('refreshToken')) {
        data.refreshToken = value;
      }
      if (key.endsWith('userData')) {
        data.userData = value;
      }
      if (key.endsWith('LastAuthUser')) {
        data.LastAuthUser = value;
        const keySplit = key.split('.');
        data.clientId = keySplit[1];
      }
    }
    return data;
  }
};

export const deleteCognitoCookies = () => {
  const domain = isDevelopment() ? 'localhost' : '.padi.com';
  const cognitoCookies = getCognitoCookies();
  if (cognitoCookies) {
    for (const [key, value] of Object.entries(cognitoCookies)) {
      // @codingStandardsIgnoreStart
      document.cookie = `${key}=${value}; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;`;
      // @codingStandardsIgnoreEnd
    }
  }
};

export const getUserIdToken = () => {
  // Check cookies first.
  const cognitoCookies = getCognitoCookies();
  if (cognitoCookies) {
    for (const [key, value] of Object.entries(cognitoCookies)) {
      if (key.endsWith('idToken')) {
        return value;
      }
    }
  }
  // Fallback on local storage.
  // const cognitoStoredToken = getLocalIdToken();
  // if (cognitoStoredToken) {
  //   return cognitoStoredToken;
  // }

  return null;
};

export const getUserRefreshToken = () => {
  const cognitoCookies = getCognitoCookies();
  if (cognitoCookies) {
    for (const [key, value] of Object.entries(cognitoCookies)) {
      if (key.endsWith('refreshToken')) {
        return value;
      }
    }
  }
  return null;
};

// Get all id, refresh and access tokens.
export const getUserTokens = () => {
  const cognitoCookies = getCognitoCookies();
  if (cognitoCookies) {
    const tokens = {
      idToken: '',
      refreshToken: '',
      accessToken: '',
    };
    for (const [key, value] of Object.entries(cognitoCookies)) {
      if (key.endsWith('idToken')) {
        tokens.idToken = value;
      }
      if (key.endsWith('refreshToken')) {
        tokens.refreshToken = value;
      }
      if (key.endsWith('accessToken')) {
        tokens.accessToken = value;
      }
    }
    return tokens;
  }
  return null;
};

export const getUserData = () => {
  // Check cookies first.
  const cognitoCookie = parseCognitoCookies();
  if (cognitoCookie?.userData) {
    const attributes = JSON.parse(cognitoCookie.userData);
    const userData: CognitoUserData = {};
    attributes['UserAttributes'].forEach(function (arrayItem: Record<string, string>) {
      userData[arrayItem.Name] = arrayItem.Value;
    });
    return userData;
  }
  // Fallback on local storage.
  // const cognitoStoredToken = getLocalIdToken();
  // if (cognitoStoredToken) {
  //   return getLocalUserData();
  // }

  return null;
};

export const getUserAffiliateTypeName = (affiliateTypeId: string) => {
  const typeIds = ['1', '2', '3', '9'];

  if (!typeIds.includes(affiliateTypeId)) {
    return null;
  }

  switch (affiliateTypeId) {
    case '1':
      return 'student';
    case '2':
      return 'store';
    case '3':
      return 'instructor';
    case '9':
      return 'consumer';
    default:
      return null;
  }
};

export const getUserMemberType = (affiliateTypeId: string) => {
  if (!affiliateTypeId) {
    return null;
  }

  const affiliateType = getUserAffiliateTypeName(affiliateTypeId);

  if (affiliateType) {
    const proTypes = ['store', 'instructor'];
    if (proTypes.includes(affiliateType)) {
      return 'pro';
    }
    return 'student';
  }

  return null;
};
