'use client';

import React from 'react';
import CtaSection, { CtaSectionProps } from 'components/padi-ui/cta-section';
import { TasticProps } from 'frontastic/tastics/types';

const CtaSectionTastic = ({ data }: TasticProps<CtaSectionProps>) => {
  return (
    <CtaSection
      image={data.image}
      eyebrow={data.eyebrow}
      title={data.title}
      titleHtag={data.titleHtag}
      subtitle={data.subtitle}
      body={data.body}
      list={data.list}
      ctaLabel={data.ctaLabel}
      ctaLink={data.ctaLink}
      bgColor={data.bgColor}
      isButton={data.isButton}
      buttonVariant={data.buttonVariant}
      imagePosition={data.imagePosition}
    />
  );
};

export default CtaSectionTastic;
