'use client';

import React from 'react';
import ProductFeaturesSplitImage, {
  ProductFeaturesSplitImageProps,
} from 'components/padi-ui/product-features/product-features-split-image';
import { TasticProps } from 'frontastic/tastics/types';

const ProductFeaturesSplitImageTastic = ({ data }: TasticProps<ProductFeaturesSplitImageProps>) => {
  return <ProductFeaturesSplitImage image={data.image} body={data.body} imagePosition={data.imagePosition} />;
};

export default ProductFeaturesSplitImageTastic;
