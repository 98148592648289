'use client';

import React from 'react';
import FaqSection, { FaqSectionProps } from 'components/padi-ui/faq-section';
import { TasticProps } from 'frontastic/tastics/types';

const FaqSectionTastic = ({ data }: TasticProps<FaqSectionProps>) => {
  return <FaqSection title={data.title} titleHtag={data.titleHtag} faqs={data.faqs} />;
};

export default FaqSectionTastic;
