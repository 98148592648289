import Link from 'components/commercetools-ui/atoms/link';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import { isStringHtml } from 'helpers/utils/isStringHtml';
import { useMemo } from 'react';
import { Reference } from 'types/reference';

export interface AnnouncementBannerButtonProps {
  hyperlink?: Reference;
  text?: string;
  backgroundColor: string;
  showArrowIcon: boolean;
  buttonTextSize: string;
  ctaType: string;
}

const AnnouncementBannerButton: React.FC<AnnouncementBannerButtonProps> = ({
  hyperlink,
  text,
  backgroundColor,
  showArrowIcon,
  buttonTextSize,
  ctaType,
}) => {
  const backgroundClass = useMemo(() => {
    if (ctaType === 'link') {
      return `flex items-center justify-center text-${buttonTextSize}`;
    }

    const buttonStyle =
      backgroundColor !== 'white'
        ? 'focus-visible:outline text-white bg-padi-blue-dark focus-visible:outline-blue-500 border-blue-500 focus-visible:border focus-visible:outline-offset-[3px]'
        : 'focus-visible:outline bg-white text-black focus-visible:outline-white border-white focus-visible:border focus-visible:outline-offset-[3px]';
    return `flex items-center justify-center rounded-sm px-3.5 py-1 h-30 ${buttonStyle} text-${buttonTextSize}`;
  }, [backgroundColor]);

  return text && hyperlink ? (
    <Link link={hyperlink} className={backgroundClass}>
      {text && isStringHtml(text) ? (
        <div dangerouslySetInnerHTML={{ __html: text }} />
      ) : (
        text && <Markdown markdown={text} />
      )}
      {showArrowIcon ? (
        <span aria-hidden="true" className="pl-8">
          &rarr;
        </span>
      ) : (
        <></>
      )}
    </Link>
  ) : (
    <></>
  );
};

export default AnnouncementBannerButton;
