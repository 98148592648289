'use client';

import React from 'react';
import NextImage from 'next/image';
import { useResolvedLocalizedObject } from 'helpers/hooks/useResolvedLocalizedObject';
import useDimensions from './hooks/useDimensions';
import useParameterizedSrc from './hooks/useParameterizedSrc';
import cloudinaryLoader from './loaders/cloudinary';
import defaultLoader from './loaders/default';
import { ImageProps } from './types';

const Image = ({ media, ratio, gravity, suffix, src, width, height, alt = '', title, ...props }: ImageProps) => {
  const parameterizedSrc = useParameterizedSrc({ ratio, gravity, suffix, media, src });
  const dimensions = useDimensions({ media, width, height, ...props });
  const resovledTitle = useResolvedLocalizedObject(title ?? '');
  const resolvedAlt = useResolvedLocalizedObject(alt ?? '');
  let isSvg = false;

  // Handle SVG's and non-cloudinary images.
  let loader = cloudinaryLoader;
  if (!media?.mediaId && !parameterizedSrc.includes('https://res.cloudinary.com')) {
    loader = defaultLoader;
  } else if (media?.format == 'svg' && media?.file) {
    isSvg = true;
  }

  return (
    <NextImage
      src={isSvg && media?.file ? media?.file : parameterizedSrc}
      loader={loader}
      alt={resolvedAlt}
      title={resovledTitle}
      {...dimensions}
      {...props}
    />
  );
};

export default Image;

export * from './types';
