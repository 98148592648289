'use client';

import VidePlayer, { VidePlayerProps } from 'components/padi-ui/video-player';
import { TasticProps } from 'frontastic/tastics/types';

const VidePlayerTastic = ({ data }: TasticProps<VidePlayerProps>) => {
  return <VidePlayer {...data} />;
};

export default VidePlayerTastic;
