'use client';

import React from 'react';
import SfmcNewsletterForm, { SfmcNewsletterFormProps } from 'components/padi-ui/form/sfmc-newsletter';
import { TasticProps } from 'frontastic/tastics/types';

const SfmcNewsletterFormTastic = ({ data }: TasticProps<SfmcNewsletterFormProps>) => {
  return (
    <SfmcNewsletterForm
      prefs={data.prefs}
      title={data.title}
      titleHtag={data.titleHtag}
      body={data.body}
      submitButtonText={data.submitButtonText}
      legalDisclaimer={data.legalDisclaimer}
      confirmationMessage={data.confirmationMessage}
      details={data.details}
    />
  );
};

export default SfmcNewsletterFormTastic;
