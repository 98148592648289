'use client';

import React from 'react';
import MenuCard, { MenuCardProps } from 'components/padi-ui/menu/menu-card';
import { TasticProps } from 'frontastic/tastics/types';

const MenuCardTastic = ({ data }: TasticProps<MenuCardProps>) => {
  return (
    <MenuCard image={data.image} title={data.title} titleUrl={data.titleUrl} body={data.body} links={data.links} />
  );
};

export default MenuCardTastic;
