import DynamicPageBreadcrumbs from './dynamic-page-breadcrumbs';
import StaticPageBreadcrumbs from './static-page-breadcrumbs';
import { BreadcrumbItem } from './types';

export interface BreadcrumbsProps {
  isStaticPage: boolean;
  breadcrumbItems: BreadcrumbItem[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ isStaticPage, breadcrumbItems }) => {
  if (isStaticPage) {
    return <StaticPageBreadcrumbs />;
  }
  return <DynamicPageBreadcrumbs items={breadcrumbItems} />;
};

export default Breadcrumbs;
