import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import { useMemo } from 'react';
import { Reference } from 'types/reference';

export interface BtnProps {
  ctaLabel: string;
  reference: Reference;
  ctaSize: 'medium' | 'large';
  ctaStyle: 'primary' | 'secondary' | 'text';
}

export interface CtaProps {
  listBtn: BtnProps[];
  alignment: 'left' | 'right' | 'center';
  bgColor: string;
}

const generateButtonClasses = (buttonVariant: string, ctaSize: string) => {
  const buttonSizeClasses = ctaSize === 'medium' ? 'h-36 md:px-12 md:py-11' : 'h-40 md:px-20 md:py-13';
  const buttonCommonClasses = `w-full md:w-auto flex items-center justify-center ${buttonSizeClasses}`;

  return buttonVariant === 'primary'
    ? `${buttonCommonClasses} bg-padi-blue hover:bg-blue-700 text-neutral-150`
    : `${buttonCommonClasses} bg-neutral-100 hover:bg-neutral-400 border-[1px] border-padi-blue text-padi-blue`;
};
const generateTextLinkClasses = (ctaSize: string, bgColor: string) => {
  const buttonSizeClasses = ctaSize === 'medium' ? 'h-36 text-sm' : 'h-40 text-base';
  const textColor = ['white', 'padi-blue-light', 'padi-gray-light', 'padi-gray-lighter'].includes(bgColor)
    ? 'text-padi-blue-darker'
    : 'text-white';
  return `flex justify-center items-center font-bold ${textColor} ${buttonSizeClasses}`;
};

const Cta: React.FC<CtaProps> = ({ listBtn, alignment, bgColor }) => {
  const alignmentClasses = useMemo(() => {
    const alignmentMapping = {
      left: 'justify-start',
      center: 'justify-center',
      right: 'justify-end',
    };
    return alignmentMapping[alignment] || '';
  }, [alignment]);

  const ctaClasses = useMemo(() => {
    const areAllCtasTextLinks = listBtn.every((cta) => cta.ctaStyle === 'text');

    return `mx-auto max-w-7xl px-24 lg:px-32 flex flex-col md:flex-row ${
      areAllCtasTextLinks ? 'gap-0 md:gap-16' : 'gap-16'
    }  ${alignmentClasses}`;
  }, [listBtn, bgColor]);

  return (
    <div className={`bg-${bgColor}`}>
      <div className={ctaClasses}>
        {listBtn.map((btn, index) =>
          btn.ctaStyle === 'text' ? (
            <div key={index} className={generateTextLinkClasses(btn.ctaSize, bgColor)}>
              <Link link={btn.reference}>{btn.ctaLabel}</Link>
            </div>
          ) : (
            <Link key={index} link={btn.reference}>
              <Button type="button" className={generateButtonClasses(btn.ctaStyle, btn.ctaSize)}>
                {btn.ctaLabel}
              </Button>
            </Link>
          ),
        )}
      </div>
    </div>
  );
};

export default Cta;
