import { FC } from 'react';
import { useParams } from 'next/navigation';
import { CheckIcon } from '@heroicons/react/24/outline';
import useClassNames from 'helpers/hooks/useClassNames';
import i18nConfig from 'i18n.config';
import { ButtonProps, ButtonVariant } from '.';
import LoadingIcon from './loadingIcon';
type FeedbackIconLayerProps = {
  loading?: ButtonProps['loading'];
  variant: ButtonProps['variant'];
};

const FeedbackIconLayer: FC<FeedbackIconLayerProps> = ({ loading, variant = 'primary' }) => {
  const { locale } = useParams();
  const langCode = locale.split('-');
  const direction = i18nConfig.rtlLngs.indexOf(langCode[0]) > -1 ? 'rtl' : 'ltr';

  const variantBackgroundRef: { [key in ButtonVariant]?: string } = {
    primary: 'bg-secondary-black',
    warning: 'bg-red-600',
  };

  const iconInWhite = variant == 'primary' || variant == 'warning';

  const layerClassName = useClassNames([
    'absolute top-0 right-0 grid h-full w-full items-center justify-center',
    variantBackgroundRef[variant] ?? 'bg-white',
  ]);

  const checkIconClassName = useClassNames(['w-20', { 'text-white': iconInWhite }]);
  const loadingIconClassName = iconInWhite ? 'fill-white' : 'fill-gray-700';

  return (
    <span className={layerClassName}>
      {loading ? (
        <LoadingIcon className={loadingIconClassName} direction={direction} />
      ) : (
        <CheckIcon className={checkIconClassName} />
      )}
    </span>
  );
};

export default FeedbackIconLayer;
