import Video from '../video';

export interface VidePlayerProps {
  youTubeLink?: string;
  isLoopVideo: boolean;
  isAudio: boolean;
  isAutoPlay: boolean;
  isFullWidth: boolean;
}

const VidePlayer: React.FC<VidePlayerProps> = ({ youTubeLink, isLoopVideo, isAudio, isAutoPlay, isFullWidth }) => {
  const wrapperClass = isFullWidth
    ? 'relative mx-auto max-w-7xl px-24 lg:px-32'
    : 'relative mx-auto max-w-3xl px-24 lg:px-32';
  return (
    <div className={wrapperClass}>
      {youTubeLink && (
        <Video youTubeLink={youTubeLink} isLoopVideo={isLoopVideo} isAudio={isAudio} isAutoPlay={isAutoPlay} />
      )}
    </div>
  );
};

export default VidePlayer;
