import React from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import { resolveReferenceTarget } from 'helpers/reference';
import { Reference } from 'types/reference';
import Image, { ImageProps } from 'frontastic/lib/image';

export interface FooterLink {
  name: string;
  reference: Reference;
}

export interface FooterColumn {
  header?: string;
  links?: FooterLink[];
}

export interface Props {
  columns: FooterColumn[];
  socialMedia?: SocialMedia[];
  newsletterHeader?: string;
  newsletterDescription?: string;
  newsletterAction?: string;
  newsletterReference?: Reference;
  copyright?: string;
}

export interface SocialMedia {
  name: string;
  logo: ImageProps;
  reference: Reference;
}

const Footer: React.FC<Props> = ({
  columns,
  newsletterHeader,
  newsletterDescription,
  newsletterReference,
  newsletterAction,
  socialMedia,
  copyright,
}) => {
  return (
    <footer className="footer bg-padi-footer" aria-labelledby="footer-heading">
      <div className="mx-auto max-w-7xl px-24 pb-32 pt-20 sm:pt-24 lg:px-32 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-32">
          <div className="grid grid-cols-2 gap-32 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-32">
              <div>
                <h4 className="text-sm font-semibold leading-6 text-white">{columns[0].header}</h4>
                <ul role="list" className="mt-24 space-y-16">
                  {columns[0].links?.map((item, index) => (
                    <li key={`${index}-${item.name}`}>
                      <a
                        href={resolveReferenceTarget(item.reference)}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-40 md:mt-0">
                <h4 className="text-sm font-semibold leading-6 text-white">{columns[1].header}</h4>
                <ul role="list" className="mt-24 space-y-16">
                  {columns[1].links?.map((item, index) => (
                    <li key={`${index}-${item.name}`}>
                      <a
                        href={resolveReferenceTarget(item.reference)}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                  <li>
                    <div id="teconsent" className="text-sm leading-6 text-gray-300 hover:text-white"></div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-32">
              <div>
                <h4 className="text-sm font-semibold leading-6 text-white">{columns[2].header}</h4>
                <ul role="list" className="mt-24 space-y-16">
                  {columns[2].links?.map((item, index) => (
                    <li key={`${index}-${item.name}`}>
                      <a
                        href={resolveReferenceTarget(item.reference)}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-40 md:mt-0">
                <h4 className="text-sm font-semibold leading-6 text-white">{columns[3].header}</h4>
                <ul role="list" className="mt-24 space-y-16">
                  {columns[3].links?.map((item, index) => (
                    <li key={`${index}-${item.name}`}>
                      <a
                        href={resolveReferenceTarget(item.reference)}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-40 xl:mt-0">
            <h4 className="text-sm font-semibold leading-6 text-white">{newsletterHeader}</h4>
            <p className="mt-32 text-sm leading-6 text-gray-300">{newsletterDescription}</p>
            <div className="mt-10 sm:shrink-0">
              <Link link={newsletterReference}>
                <Button variant="primary" className="flex w-full items-center justify-center">
                  {newsletterAction}
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="mt-40 border-t border-white/10 pt-20 sm:mt-40 md:flex md:items-center md:justify-between">
          <div className="flex space-x-24 md:order-2">
            {socialMedia?.map((item, index) => (
              <a
                key={`${index}-${item.name}`}
                href={resolveReferenceTarget(item.reference)}
                className="text-gray-500 hover:text-gray-400"
              >
                <span>{item.name}</span>
                <Image {...item.logo} className="h-24 w-24" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-32 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">{copyright}</p>
        </div>
      </div>
      <div id="consent_blackbar"></div>
    </footer>
  );
};

export default Footer;
