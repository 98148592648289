import { isNonProd } from 'helpers/utils/environment';

/**
 * Use this to convert urls from prod domains to non-prod.
 *
 * @param padiDomains  Object of Padi domains.
 * @param url A url to a prod Padi domain.
 * @returns prod or nonprod url.
 */
export const switchToPadiNonProdDomain = (padiDomains: { [key: string]: string }, url: string) => {
  if (!isNonProd()) return url;
  if (!url) return '';
  if (!padiDomains) return url;
  const domains = [
    {
      prod: 'https://learning.padi.com',
      nonprod: padiDomains?.learning,
      redirectUri: padiDomains?.learning,
    },
    {
      prod: 'https://account.padi.com',
      nonprod: padiDomains?.account,
    },
    {
      prod: 'https://pro.padi.com',
      nonprod: padiDomains?.pro,
      redirectUri: `${padiDomains?.pro}/me/dashboard`,
    },
    {
      prod: 'https://www.padi.com',
      nonprod: padiDomains?.www,
      redirectUri: `${padiDomains?.www}/padi-sso/callback`,
    },
  ];

  let npurl;
  domains.some((domain) => {
    if (url.includes(domain.prod)) {
      npurl = url.replace(domain.prod, domain.nonprod ?? domain.prod);
      return npurl;
    }
  });
  return npurl ?? url;
};
