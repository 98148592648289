'use client';

import { useEffect, useState } from 'react';
import { useParams } from 'next/navigation';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import useCloseFlyouts from 'helpers/hooks/useCloseFlyouts';
import { getTranslationLanguages } from 'project.config';
import PadiGeolocationModal from '.';

export interface PadiGeolocationModalButtonProps {
  externalSyncIsOpen?: any;
}

const PadiGeolocationModalButton: React.FC<PadiGeolocationModalButtonProps> = ({ externalSyncIsOpen }) => {
  const { locale } = useParams();

  const [languageNativeName, setLanguageNativeName] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeFlyouts = useCloseFlyouts();

  const openLocaleModal = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    closeFlyouts();
    setIsOpen(true);
  };

  const syncIsOpen = (open: boolean) => {
    setIsOpen(false);
  };

  useEffect(() => {
    const localeParts = locale.split('-');
    const langcode = localeParts[0];
    const languages = getTranslationLanguages();

    for (const [key, value] of Object.entries(languages as { [key: string]: any })) {
      if (langcode == value.twoDigitCode) {
        setLanguageNativeName(value.nativeName);
      }
    }
  }, []);

  return (
    <>
      {languageNativeName && (
        <button className="inline-flex items-center gap-x-1 text-xs leading-6 text-gray-900" onClick={openLocaleModal}>
          <span className="material-symbols-outlined block h-full w-auto" aria-hidden="true">
            language
          </span>
          {languageNativeName}
          <ChevronDownIcon className="size-20" aria-hidden="true" />
        </button>
      )}
      <PadiGeolocationModal isOpen={isOpen} syncIsOpen={syncIsOpen}></PadiGeolocationModal>
    </>
  );
};

export default PadiGeolocationModalButton;
