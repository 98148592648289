import { useMemo } from 'react';
import BreadcrumbsUI from './breadcrumbs-ui';
import type { BreadcrumbItem } from './types';

const DynamicPageBreadcrumbs: React.FC<{ items: BreadcrumbItem[] }> = ({ items }) => {
  const convertItems = useMemo(() => {
    // the url should not start with forward slash
    const _items = items.map((item) => {
      let url;
      if (item.url && item.url.length > 0) {
        url = /^\//.test(item.url) ? item.url.slice(1) : item.url; // remove the first icon /
      }
      return {
        ...item,
        url,
      };
    });

    if (_items[0].url !== '') {
      _items.unshift({ label: 'Home', url: '' });
    }
    return _items;
  }, [items]);

  return <BreadcrumbsUI items={convertItems} />;
};

export default DynamicPageBreadcrumbs;
