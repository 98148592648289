'use client';

import { Product } from 'shared/types/product/Product';
import PadiProductFaqSection, { PadiProductFaqSectionProps } from 'components/padi-product/faq-section';
import { DataSource } from 'types/datasource';
import { TasticProps } from 'frontastic/tastics/types';

const PadiProductFaqSectionTastic = ({
  data,
}: TasticProps<DataSource<{ product: Product }> & PadiProductFaqSectionProps>) => {
  if (!data?.data?.dataSource?.product || !data?.attributeSection) return null;

  return (
    <PadiProductFaqSection
      product={data.data.dataSource.product}
      titleHtag={data.titleHtag}
      attributeSection={data.attributeSection}
    />
  );
};

export default PadiProductFaqSectionTastic;
