'use client';

import React from 'react';
import { TasticProps } from 'frontastic/tastics/types';
import { PromotionalTileProps } from 'components/padi-ui/promotional-tile/types';
import PromotionalTile from 'components/padi-ui/promotional-tile';
import { PromotionalTileCTAProps } from 'components/padi-ui/promotional-tile/types';

export interface PromotionalTileTasticProps extends PromotionalTileProps {
  ctaGroup?: [PromotionalTileCTAProps];
}

const PromotionalTileTastic = ({ data }: TasticProps<PromotionalTileTasticProps>) => {
  return <PromotionalTile {...data} ctaStyle={data.ctaGroup?.[0]} />;
};

export default PromotionalTileTastic;
