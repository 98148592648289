'use client';

import { useEffect, useCallback } from 'react';
import { getLocalStorage, setLocalStorage } from 'helpers/utils/localStorage';
import { useAccount } from 'frontastic/hooks';

export interface PadiDiveShopHeaderProps {
  urlParams: Record<string, string>;
}

const PadiDiveShopStorage: React.FC<PadiDiveShopHeaderProps> = ({ urlParams }) => {
  const irra = (urlParams?.irra as string) || '';
  const { getProjectConfig } = useAccount();

  const getDiveShop = useCallback(async (irra: string) => {
    const diveShopEndpoint = await getProjectConfig('EXTENSION_PADI_APIS_DIVE_SHOP_ENDPOINT');
    fetch(`${diveShopEndpoint?.setting}/${irra}/`)
      .then((res) => res.json())
      .then((data) => {
        const now = new Date();
        const ttl = now.setDate(now.getDate() + 30);
        if (data?.results[0]) setLocalStorage('affiliateDiveShop', irra, ttl);
      });
  }, []);

  useEffect(() => {
    if (irra) {
      getDiveShop(irra);
    }
    getLocalStorage('affiliateDiveShop');
  }, [irra]);

  return <></>;
};

export default PadiDiveShopStorage;
