'use client';

import { Product } from 'shared/types/product/Product';
import ProductHeaderSection, { ProductHeaderSectionProps } from 'components/padi-product/header-section';
import { DataSource } from 'types/datasource';
import { TasticProps } from 'frontastic/tastics/types';

const ProductHeaderSectionTastic = ({
  data,
}: TasticProps<DataSource<{ product: Product }> & ProductHeaderSectionProps>) => {
  if (!data?.data?.dataSource?.product) return null;

  return (
    <ProductHeaderSection
      product={data?.data?.dataSource.product}
      attributeSection={data?.attributeSection}
      titleHtag={data?.titleHtag}
      titleSize={data?.titleSize}
      bgColor={data?.bgColor}
      textAlign={data?.textAlign}
    />
  );
};

export default ProductHeaderSectionTastic;
