import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import Image, { ImageProps } from 'frontastic/lib/image';
import { useEffect, useMemo, useState } from 'react';
import { Reference } from 'types/reference';
import Video from '../video';

export interface HeroProps {
  image: ImageProps;
  title: string;
  ctaLabel?: string;
  ctaReference?: Reference;
  youTubeLink?: string;
  isLoopVideo: boolean;
  isAudio: boolean;
  isAutoPlay: boolean;
  body?: string;
  secondCtaLabel?: string;
  secondCtaReference?: Reference;
  height?: 'half' | 'full';
  horizontalAlign?: 'left' | 'center';
  verticalAlign?: 'top' | 'center' | 'bottom';
  imageBrightness?: boolean;
  ctaStyle?: 'primary' | 'secondary';
  secondCtaStyle?: 'primary' | 'secondary';
}

const buttonCommonClasses = 'md:px-48 md:py-12 h-40';

const Hero: React.FC<HeroProps> = ({
  image,
  title,
  ctaLabel,
  ctaReference,
  youTubeLink,
  isLoopVideo,
  isAudio,
  isAutoPlay,
  body,
  secondCtaLabel,
  secondCtaReference,
  height,
  horizontalAlign,
  verticalAlign,
  imageBrightness,
  ctaStyle,
  secondCtaStyle,
}) => {
  const cropFocus = useMemo(() => {
    if (image?.gravity?.coordinates) {
      const right = Math.ceil((image.gravity?.coordinates.x / (image.media?.width as number)) * 100);
      const top = Math.ceil((image.gravity?.coordinates.y / (image.media?.height as number)) * 100);

      return `right ${right.toString()}% top ${top.toString()}%`;
    }

    if (image?.gravity?.mode === 'center') {
      return 'center';
    }

    return 'initial';
  }, [image]);

  const wrapperClasses = useMemo(() => {
    if (height === 'half') {
      return 'relative bg-padi-gray-darkest h-[222px] md:h-[266px] lg:h-[334px]';
    }
    return 'relative bg-padi-gray-darkest h-[296px] md:h-[532px] lg:h-[668px]';
  }, [height]);

  const contentWrapperClasses = useMemo(() => {
    const styles: { [key: string]: string } = {
      'full-top': 'pt-32 md:pt-64 lg:pt-96',
      'full-bottom': 'pb-32 md:pb-64 lg:pb-96 justify-end',
    };
    const verticalAlignClass = styles[`${height}-${verticalAlign}`] || ' justify-center';

    return `w-full h-full flex flex-col mx-auto max-w-7xl px-20 md:px-64 lg:px-32 text-${horizontalAlign} ${verticalAlignClass}`;
  }, [horizontalAlign, verticalAlign, height]);

  const buttonClasses = useMemo(() => {
    return [ctaStyle, secondCtaStyle].map((style) => {
      if (style === 'primary') {
        return `${buttonCommonClasses} bg-padi-blue hover:bg-blue-700 text-neutral-150`;
      }
      return `${buttonCommonClasses} bg-neutral-100 hover:bg-neutral-400 border-[1px] border-padi-blue  text-padi-blue`;
    });
  }, [ctaStyle, secondCtaStyle]);

  const buttonWrapperClasses = useMemo(() => {
    const justifyContent = horizontalAlign === 'center' ? 'justify-center' : 'justify-start';
    return `mt-20 flex flex-wrap items-center gap-32 md:mt-24 lg:mt-32 ${justifyContent}`;
  }, [horizontalAlign]);

  const truncateText = (text: string | undefined, maxLength: number) => {
    if (text && text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };
  const bodyDisplay = useMemo(() => truncateText(body, 100), [body]);
  const titleDisplay = useMemo(() => truncateText(title, 56), [title]);
  const [width, setWidth] = useState(window.innerWidth);

  const maxWidth = useMemo(() => {
    return width < 1024 ? 'none' : (width * 2) / 3;
  }, [width]);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return (
    <div className="relative w-full">
      <div className={wrapperClasses}>
        {image?.media && (
          <Image
            {...image}
            priority
            loading="eager"
            alt={title}
            fill
            style={{ objectFit: 'cover', objectPosition: cropFocus }}
            className={imageBrightness ? 'brightness-75' : 'filter-none'}
          />
        )}
        {youTubeLink && (
          <Video youTubeLink={youTubeLink} isLoopVideo={isLoopVideo} isAudio={isAudio} isAutoPlay={isAutoPlay} />
        )}
      </div>
      <div className="absolute bottom-0 left-0 right-0 top-0 w-full">
        <div className={contentWrapperClasses}>
          <div className={horizontalAlign === 'center' ? 'mx-auto' : ''} style={{ maxWidth: maxWidth }}>
            <Typography as="h1" className="mt-22 text-26 font-medium text-white md:mt-32 md:text-36 lg:text-48">
              {titleDisplay}
            </Typography>
            {bodyDisplay && (
              <p className="pt-20 text-14 font-regular text-white md:text-16 lg:text-18">{bodyDisplay}</p>
            )}
            <div className={buttonWrapperClasses}>
              {ctaLabel && (
                <Link link={ctaReference}>
                  <Button className={buttonClasses[0]}>
                    <Typography as="span" className="text-12  md:text-14 lg:text-16">
                      {ctaLabel}
                    </Typography>
                  </Button>
                </Link>
              )}
              {secondCtaLabel && (
                <Link link={secondCtaReference}>
                  <Button className={buttonClasses[1]}>
                    <Typography as="span" className="text-12 md:text-14 lg:text-16">
                      {secondCtaLabel}
                    </Typography>
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
