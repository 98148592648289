'use client';

import React from 'react';
import SimplifiedFooter, { SimplifiedFooterProps } from 'components/padi-ui/simplified-footer';
import { TasticProps } from '../../types';

const SimplifiedFooterTastic = ({ data }: TasticProps<SimplifiedFooterProps>) => {
  return <SimplifiedFooter links={data.links} />;
};

export default SimplifiedFooterTastic;
