'use client';

import { Product } from 'shared/types/product/Product';
import PadiProductFeatureSectionCheckList, {
  PadiProductFeatureSectionCheckListProps,
} from 'components/padi-product/feature-section/check-list';
import { DataSource } from 'types/datasource';
import { TasticProps } from 'frontastic/tastics/types';

const PadiProductFeatureSectionCheckListTastic = ({
  data,
}: TasticProps<DataSource<{ product: Product }> & PadiProductFeatureSectionCheckListProps>) => {
  if (!data?.data?.dataSource?.product || !data?.attributeSection) return null;

  return (
    <PadiProductFeatureSectionCheckList
      product={data.data.dataSource.product}
      titleHtag={data?.titleHtag}
      attributeSection={data.attributeSection}
      bgColor={data?.bgColor}
      fgColor={data?.fgColor}
    />
  );
};

export default PadiProductFeatureSectionCheckListTastic;
