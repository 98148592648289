'use client';

import React from 'react';
import FeatureSectionCheckList, { FeatureSectionCheckListProps } from 'components/padi-ui/feature-section/check-list';
import { TasticProps } from 'frontastic/tastics/types';

const FeatureSectionCheckListTastic = ({ data }: TasticProps<FeatureSectionCheckListProps>) => {
  return (
    <FeatureSectionCheckList
      title={data.title}
      titleHtag={data?.titleHtag}
      bgColor={data.bgColor}
      fgColor={data.fgColor}
      features={data.features}
    />
  );
};

export default FeatureSectionCheckListTastic;
