export interface ListDescriptionProps {
  title: string;
  description: string;
  items: object;
}

const ListDescription: React.FC<ListDescriptionProps> = ({ title, description, items }) => {
  return (
    <div>
      <div className="px-16 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">{title}</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{description}</p>
      </div>
      <div className="mt-24 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          {Object.values(items).map((item, index) => (
            <div key={index} className="px-16 py-24 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium text-gray-900">{item.label}</dt>
              <dd className="mt-4 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{item.content}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default ListDescription;
