'use client';

import { Product } from 'shared/types/product/Product';
import PadiProductStats, { PadiProductStatsProps } from 'components/padi-product/stats';
import { DataSource } from 'types/datasource';
import { TasticProps } from 'frontastic/tastics/types';

const PadiSubscriptionStatsTastic = ({
  data,
}: TasticProps<DataSource<{ product: Product }> & PadiProductStatsProps>) => {
  if (!data?.data?.dataSource?.product) return null;

  return (
    <PadiProductStats
      product={data?.data?.dataSource.product}
      attributeSection={data?.attributeSection}
      bgColor={data?.bgColor}
    />
  );
};

export default PadiSubscriptionStatsTastic;
