export interface FeatureSectionCheckListProps {
  title: string;
  titleHtag?: string;
  bgColor: string;
  fgColor: string;
  features: object;
}

const FeatureSectionCheckList: React.FC<FeatureSectionCheckListProps> = ({
  title,
  titleHtag,
  bgColor,
  fgColor,
  features,
}) => {
  const classNameMain = `bg-${bgColor} text-${fgColor} pb-16 pt-32 lg:pt-64 lg:pb-70`;
  const featuresArray = Object.values(features);
  const TitleHtag = titleHtag ? (titleHtag as keyof JSX.IntrinsicElements) : 'h2';

  return (
    <div className={classNameMain}>
      <div className="mx-auto max-w-7xl px-24 lg:px-32">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <TitleHtag className="text-2xl font-medium">{title}</TitleHtag>
        </div>
        <div className="mx-auto mb-16 mt-32 max-w-2xl lg:mb-0 lg:mt-44 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-24 lg:max-w-none lg:grid-cols-2 lg:gap-y-46">
            {featuresArray.map((feature, index) => {
              return (
                <div key={index} className="flex flex-col">
                  <dt className="flex items-center gap-x-3 font-medium">
                    <span className="material-symbols-outlined mr-12 size-22 flex-none" aria-hidden="true">
                      check_circle
                    </span>
                    {feature.title}
                  </dt>
                </div>
              );
            })}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default FeatureSectionCheckList;
