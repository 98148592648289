const screenSizes = {
  smallMobile: 320, // non-tailwind
  mobile: 480, // tailwind sm
  tablet: 744, // tailwind md
  desktop: 1024, // tailwind lg
  mediumDesktop: 1280, // tailwind xl
  largeDesktop: 1440, // tailwind 2xl
  hugeDesktop: 1666, // tailwind 3xl
};

export const { smallMobile, mobile, tablet, desktop, mediumDesktop, largeDesktop, hugeDesktop } = screenSizes;
