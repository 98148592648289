'use client';

import React from 'react';
import Accordion, { AccordionProps } from 'components/padi-ui/accordion';
import { TasticProps } from 'frontastic/tastics/types';

const AccordionTastic = ({ data }: TasticProps<AccordionProps>) => {
  return <Accordion items={data.items} schemaType={data.schemaType} />;
};

export default AccordionTastic;
