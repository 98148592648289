'use client';

import React from 'react';
import HeaderTopbar, { HeaderTopbarProps } from 'components/padi-ui/header/topbar';
import { TasticProps } from 'frontastic/tastics/types';
import CurrencySelector from 'components/padi-ui/currency-selector';
import { useProjectCurrencies } from 'frontastic/hooks';

const HeaderTopbarTastic = ({ data }: TasticProps<HeaderTopbarProps>) => {
  const getProjectCurrencies = useProjectCurrencies();
  const { data: projectCurrencyData } = getProjectCurrencies!;

  let currencyCelector;
  if (projectCurrencyData) {
    const currencies = projectCurrencyData?.currencies;
    if (currencies && currencies.length) {
      currencies.forEach(function (currency) {
        currency.current = currency?.code == 'USD' ? true : false;
      });

      currencyCelector = <CurrencySelector currencies={currencies} />;
    }
  }

  return <HeaderTopbar menus={data?.menus} currencySelector={currencyCelector} />;
};

export default HeaderTopbarTastic;
