'use client';

import { Product } from 'shared/types/product/Product';
import ProductDescription from 'components/padi-product/description';
import { DataSource } from 'types/datasource';
import { TasticProps } from 'frontastic/tastics/types';

const ProductDetailsTastic = ({ data }: TasticProps<DataSource<{ product: Product }>>) => {
  if (!data?.data?.dataSource?.product) return null;

  return <ProductDescription product={data?.data?.dataSource.product} />;
};

export default ProductDetailsTastic;
