/**
 * Set local storage with option of expiration date.
 *
 * @param key
 * @param value
 * @param ttl
 *  Timestamp.
 */
export const setLocalStorage = (key: string, value: string, ttl?: number) => {
  if (!key || !value) return;
  const item = {
    value: value,
    expiry: ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

/**
 * Get local storage and check/remove if it has past expire date.
 *
 * @param key
 * @returns
 *   The local storage item value.
 */
export const getLocalStorage = (key: string) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  try {
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  } catch (e) {}

  return itemStr;
};
