import { useMemo } from 'react';
import { Product } from 'shared/types/product/Product';
import ProductCtaAction from 'components/padi-product/cta-action';
import ProductDescription from 'components/padi-product/description';
import PadiProductFeatureSectionCheckList from 'components/padi-product/feature-section/check-list';
import PadiProductFeatureSectionDynamicList from 'components/padi-product/feature-section/dynamic-list';
import ProductHeaderSection from 'components/padi-product/header-section';

export interface CourseProps {
  product: Product;
}

const Course: React.FC<CourseProps> = ({ product }) => {
  const imageCTA1 = useMemo(
    () => ({
      media: {
        alt: 'image of three students sitting around a table looking at their phone and an instructor pointing at one of the tablet. ',
        file: 'https://res.cloudinary.com/dlwdq84ig/image/upload/f_auto,q_auto,w_auto,dpr_auto/v1713398007/uuvcuvc26dp9izyy0nez.jpg?',
      },
      title: '',
    }),
    [],
  );
  const imageCTA2 = useMemo(
    () => ({
      media: {
        alt: "image of a student paying attention to his instructor's explanation while both of them are looking at some learning materials on their desk. ",
        file: 'https://res.cloudinary.com/dlwdq84ig/image/upload/f_auto,q_auto,w_auto,dpr_auto/v1713398007/mlafw2t55ojsvd3pg7cj.jpg?',
      },
      title: '',
    }),
    [],
  );
  const imageCTA3 = useMemo(
    () => ({
      media: {
        alt: 'jump',
        file: 'https://res.cloudinary.com/dlwdq84ig/image/upload/f_auto,q_auto,w_auto,dpr_auto/v1702946030/fmjdpfcjn4ptq7xbhuqw.jpg?',
      },
      title: '',
    }),
    [],
  );

  return (
    <div>
      <h3 className="mx-auto mb-20 max-w-7xl px-24 text-xl font-bold leading-loose md:text-26 lg:px-32">
        {product.name}
      </h3>
      <ProductDescription product={product} titleSize="text-2xl" />
      <PadiProductFeatureSectionCheckList
        product={product}
        bgColor="padi-blue-dark"
        attributeSection="section-1"
        fgColor="white"
        titleHtag="h3"
      />
      <PadiProductFeatureSectionDynamicList
        product={product}
        bgColor="padi-gray-lighter"
        attributeSection="section-2"
        titleHtag="h4"
      />
      <ProductHeaderSection
        product={product}
        attributeSection="section-3"
        titleHtag="h2"
        titleSize="text-4xl"
        bgColor="white"
        textAlign="left"
      />
      <ProductCtaAction
        product={product}
        titleHtag="h3"
        attributeSection="section-4"
        bgColor="padi-gray-lighter"
        image={imageCTA1}
        imagePosition="left"
      />
      <div className="h-60 bg-white"></div>
      <ProductCtaAction
        product={product}
        titleHtag="h3"
        attributeSection="section-5"
        bgColor="padi-gray-lighter"
        image={imageCTA2}
        imagePosition="left"
      />
      <div className="h-60 bg-white"></div>
      <ProductCtaAction
        product={product}
        titleHtag="h3"
        attributeSection="section-6"
        bgColor="padi-gray-lighter"
        image={imageCTA3}
        imagePosition="left"
      />
      <div className="h-60 bg-white"></div>
    </div>
  );
};

export default Course;
