import { useMemo } from 'react';
import { Product } from 'shared/types/product/Product';
import useCurrentBreakpoint from 'helpers/hooks/useCurrentBreakpoint';
import DesktopLayout from './desktop-layout';
import MobileLayout from './mobile-layout';
import ProductCard from './product-card';

export interface TabsProps {
  products: Product[];
  setSelectedProduct: (product: Product) => void;
  selectedProductId?: string;
}

const Tabs: React.FC<TabsProps> = ({ products, setSelectedProduct, selectedProductId }) => {
  const screenSize = useCurrentBreakpoint();
  const isMobileScreen = useMemo(() => {
    return screenSize && ['smallMobile', 'mobile'].includes(screenSize);
  }, [screenSize]);

  const listProductUi = useMemo(
    () =>
      products.map((product) => {
        return (
          <ProductCard
            key={product.name}
            product={product}
            selectProduct={setSelectedProduct}
            isSelected={product.productId === selectedProductId}
          />
        );
      }),
    [products, selectedProductId, setSelectedProduct],
  );

  if (isMobileScreen) {
    return <MobileLayout>{listProductUi}</MobileLayout>;
  }

  return <DesktopLayout totalProductItem={products.length}>{listProductUi}</DesktopLayout>;
};

export default Tabs;
