import { useMemo } from 'react';
import TestimonialItem, { TestimonialItemProps } from './testimonial-item';

export interface TestimonialsProps {
  testimonials: TestimonialItemProps[];
  bgColor: string;
}

const Testimonials: React.FC<TestimonialsProps> = ({ testimonials, bgColor }) => {
  const styles = useMemo(() => {
    if (bgColor === 'padi-gray-darkest') {
      return {
        textColor: 'text-white',
        textColorTitle: 'text-white',
        lineColor: 'border-white',
      };
    }
    return {
      textColor: 'text-padi-gray-darkest',
      textColorTitle: 'text-padi-gray-darker',
      lineColor: 'border-gray-900/10',
    };
  }, [bgColor]);

  const testimonialLayouts = useMemo(
    () => [
      'flex flex-col pb-40 sm:pb-32 lg:pb-0 lg:pr-32 xl:pr-100',
      `flex flex-col border-t ${styles.lineColor} pt-40 sm:pt-32 lg:border-l lg:border-t-0 lg:pl-32 lg:pt-0 xl:pl-100`,
    ],
    [styles.lineColor],
  );

  return (
    <section className="mx-auto max-w-7xl px-24 lg:px-32">
      <div className={`px-24 lg:px-32 bg-${bgColor} rounded-lg py-96 sm:py-128  ${styles.textColor}`}>
        <div className="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          {testimonials.map((item, index) => (
            <div key={index} className={testimonialLayouts[index]}>
              <TestimonialItem
                logo={item.logo}
                textColorTitle={styles.textColorTitle}
                body={item.body}
                name={item.name}
                title={item.title}
                avatar={item.avatar}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
