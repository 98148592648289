'use client';

import React from 'react';
import CategoryPreviews, { CategoryPreviewsProps } from 'components/padi-ui/category-previews';
import { TasticProps } from 'frontastic/tastics/types';

const CategoryPreviewsTastic = ({ data }: TasticProps<CategoryPreviewsProps>) => {
  return <CategoryPreviews {...data} />;
};

export default CategoryPreviewsTastic;
