import Markdown from 'components/commercetools-ui/organisms/markdown';
import Image from 'frontastic/lib/image';

export interface ProductFeaturesSplitImageProps {
  image: object;
  body: string;
  imagePosition: 'left' | 'right';
}

const ProductFeaturesSplitImage: React.FC<ProductFeaturesSplitImageProps> = ({ image, body, imagePosition }) => {
  let classNameColumn1 =
    'aspect-h-2 aspect-w-3 overflow-hidden sm:aspect-w-5 lg:aspect-none lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-16';
  let column2Start = 'lg:col-start-2';

  if (imagePosition == 'right') {
    classNameColumn1 = classNameColumn1
      .replace('lg:pr-4 xl:pr-16', 'lg:pl-4 xl:pl-16')
      .concat(' right-0 lg:order-last');
    column2Start = 'lg:col-start-1';
  }

  return (
    <div className="bg-white">
      <section aria-labelledby="features-heading" className="relative flex">
        <div className={classNameColumn1}>
          <Image {...image} className="h-full w-full object-cover object-center lg:h-full lg:w-full" />
        </div>
        <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 sm:pb-32 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:pt-32">
          <div className={column2Start}>
            <div className="prose max-w-full">
              <Markdown markdown={body} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductFeaturesSplitImage;
