'use client';

import BentoBox, { BentoBoxProps } from 'components/padi-ui/bento-box';
import { TasticProps } from 'frontastic/tastics/types';

export type Bento5050TasticProps = Pick<BentoBoxProps, 'promotionalTiles'>;

const Bento5050Tastic = ({ data }: TasticProps<Bento5050TasticProps>) => {
  return <BentoBox promotionalTiles={data.promotionalTiles} gridType="50-50" />;
};

export default Bento5050Tastic;
