import { ImageLoaderProps } from 'next/image';

function cloudinaryLoader({ src, width }: ImageLoaderProps) {
  const studioCloudinaryName = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME;
  const cloudinaryHost = 'https://res.cloudinary.com';
  const root = `${cloudinaryHost}/${studioCloudinaryName}/image/upload`;
  const query = (src as string).split('?')[1]; //contains the query paremeters
  const queries = new URLSearchParams(query); //injected query params
  const ratio = queries.get('ratio'); //aspect asset ratio
  const gravity = queries.get('gravity'); //required gravity: faces, body, etc..
  const x = queries.get('x__coord'); //x coordinate for custom gravity
  const y = queries.get('y__coord'); //y coordinate for custom gravity
  const isSvg = src.includes('.svg'); // Svgs are blurry when used with f_auto.

  // Transformations.
  const params = ratio
    ? [
        ...(!isSvg ? ['f_auto'] : []),
        ...['w_auto', 'q_90', 'dpr_auto', 'c_crop'],
        ...(gravity ? 'g_' + gravity : []),
        ...(gravity === 'custom' ? ['x_' + x, 'y_' + y] : []),
        ...(ratio ? ['ar_' + ratio] : []),
      ]
    : [...(!isSvg ? ['f_auto'] : []), ...['q_90', 'w_auto', 'dpr_auto']];

  const transformations = params.join(',');

  // When src is a full url.
  if (src.includes(cloudinaryHost)) {
    const transformations = params.join(',');
    const imageUrl = src.replace('upload/', `upload/${transformations}/`);
    return imageUrl;
  }

  // When src is a studio media id.
  let source = src.split('?')[0];
  source = source[0] === '/' ? source.slice(1) : source;
  return `${root}/${transformations}/${source}`;
}

export default cloudinaryLoader;
