import FaqSection from 'components/padi-ui/faq-section';

export interface PadiProductFaqSectionProps {
  product: Record<string, any>;
  titleHtag?: string;
  attributeSection: string;
}

const PadiProductFaqSection: React.FC<PadiProductFaqSectionProps> = ({ product, titleHtag, attributeSection }) => {
  try {
    const productContent = JSON.parse(product?.variants?.[0].attributes[attributeSection]);
    const title = productContent?.title ?? '';
    const faqs = productContent?.faqs ?? [];

    return <FaqSection title={title} titleHtag={titleHtag} faqs={faqs} />;
  } catch (e) {
    return <></>;
  }
};

export default PadiProductFaqSection;
