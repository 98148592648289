'use client';

import { Product } from 'shared/types/product/Product';
import PadiProductAccordionSection, { PadiProductAccordionSectionProps } from 'components/padi-product/accordion';
import { DataSource } from 'types/datasource';
import { TasticProps } from 'frontastic/tastics/types';

const PadiProductAccordionSectionTastic = ({
  data,
}: TasticProps<DataSource<{ product: Product }> & PadiProductAccordionSectionProps>) => {
  if (!data?.data?.dataSource?.product || !data?.attributeSection) return null;

  return (
    <PadiProductAccordionSection
      product={data.data.dataSource.product}
      attributeSection={data.attributeSection}
      titleHtag={data.titleHtag}
      schemaType={data.schemaType}
    />
  );
};

export default PadiProductAccordionSectionTastic;
