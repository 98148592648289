import { useMemo } from 'react';
import Slider from 'components/commercetools-ui/atoms/slider';

const MobileLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const arrowButtonStyles = useMemo(
    () => ({
      transform: 'translateY(-150%)',
      width: '32px',
      height: '32px',
      background: 'rgba(255, 255, 255, 0.7)',
      padding: '10px 12px',
      borderRadius: '50%',
    }),
    [],
  );

  const arrowIconStyle = useMemo(
    () => ({
      borderColor: 'black',
      padding: '6px',
    }),
    [],
  );

  return (
    <div className="relative mt-6 w-full ">
      <Slider
        arrows
        allowArrowsOnTouchDevice
        allowTouchMove
        innerArrows
        solidArrows
        slidesPerView={1.1}
        slidesPerGroup={1}
        dots={false}
        spaceBetween={8}
        nextButtonStyles={arrowButtonStyles}
        prevButtonStyles={arrowButtonStyles}
        nextArrowStyles={arrowIconStyle}
        prevArrowStyles={arrowIconStyle}
      >
        {children}
      </Slider>
    </div>
  );
};

export default MobileLayout;
