'use client';

import React from 'react';
import { TasticProps } from 'frontastic/tastics/types';
import CurrencySelector, { CurrencySelectorProps } from 'components/padi-ui/currency-selector';
import { useProjectCurrencies } from 'frontastic/hooks';

const CurrencySelectorTastic = ({ data }: TasticProps<CurrencySelectorProps>) => {
  const getProjectCurrencies = useProjectCurrencies();
  const {data: projectCurrencyData} = getProjectCurrencies!;

  if (projectCurrencyData) {
    const currencies = projectCurrencyData?.currencies;
    if (currencies && currencies.length) {
      currencies.forEach(function (currency) {
        currency.current = currency?.code == 'USD' ? true : false;
      });

      return <CurrencySelector currencies={currencies} />;
    }
  }
};

export default CurrencySelectorTastic;
