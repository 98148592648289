import NextLink from 'next/link';
import Link from 'components/commercetools-ui/atoms/link';
import { Reference } from 'types/reference';
import Image from 'frontastic/lib/image';

export interface MenuCardProps {
  image: {
    title: string;
    media: {
      alt: string;
      file: string;
    };
  };
  title: string;
  titleUrl: Reference;
  body: string;
  links: object;
}

const MenuCard: React.FC<MenuCardProps> = ({ image, title, titleUrl, body, links }) => {
  const imageSrc = image?.media?.file;
  const imageAlt = image?.media?.alt;
  const imageTitle = image?.title;
  const classNameLinkDefault = 'block mt-4 text-base text-padi-gray-darker py-2 hover:text-padi-blue';
  const classNameLinkBlue =
    'block mt-4 text-base font-bold whitespace-nowrap py-2 text-padi-blue hover:text-padi-blue-darker';
  return (
    <>
      <div className="relative text-base text-gray-500 sm:text-sm">
        <div className="max-h-[300px] overflow-hidden rounded-none">
          {imageSrc && (
            <Image
              className="mb-16 aspect-video h-auto w-full object-cover object-center"
              src={imageSrc}
              alt={imageAlt}
              title={imageTitle}
            />
          )}
        </div>

        {title && (
          <h2 className="mb-8 text-lg font-semibold text-blue-950">
            {titleUrl ? (
              <Link link={titleUrl} target={titleUrl?.openInNewWindow ? '_blank' : '_self'} title={title}>
                {title}
              </Link>
            ) : (
              title
            )}
          </h2>
        )}

        {body && <p className="mb-16 space-y-2 text-sm">{body}</p>}

        <div className="mb-16 space-y-4 text-base text-blue-950">
          {links &&
            Object.values(links).map((item, index) => (
              <div className="flex" key={index}>
                {item.image?.media?.file && (
                  <Image
                    className="mb-8 mr-16 aspect-square h-64 w-64 shrink-0 self-center object-cover object-center"
                    src={item.image?.media?.file}
                    alt={item.image?.media?.alt}
                    title={item.image?.title}
                  />
                )}
                {item.url?.pageFolder?._url || item.url?.link ? (
                  <Link
                    link={item.url?.pageFolder?._url ?? item.url?.link}
                    className={item.style == 'blue' ? classNameLinkBlue : classNameLinkDefault}
                    target={item.url?.openInNewWindow ? '_blank' : '_self'}
                    title={item.label}
                  >
                    {item.label}
                  </Link>
                ) : (
                  item.label
                )}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default MenuCard;
